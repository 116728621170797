import React, { useMemo, useCallback } from 'react';
import { formatTotalPrice } from '../utils/helpers';
import format from 'date-fns/format';
import { LineChart } from '@mui/x-charts/LineChart';

const Patterns = () => {
  return (
    <defs>
      <pattern
        id="dotPattern"
        patternUnits="userSpaceOnUse"
        width="4"
        height="4"
      >
        <circle cx="2" cy="2" r="1" fill="#e9ecfc" />
      </pattern>
      <pattern
        id="dotPattern2"
        patternUnits="userSpaceOnUse"
        width="4"
        height="4"
      >
        <circle cx="2" cy="2" r="1" fill="#e2f0eb" />
      </pattern>
    </defs>);
};

const SalesChart = ({ allChartData, selectedSeries, selectedRange }) => {
  const xData = useMemo(() => {
    return allChartData.map((entry) => entry.date);
  }, [allChartData]);

  const yDataSales = useMemo(() => {
    return allChartData.map((entry) => entry.sales);

  }, [allChartData]);

  const yDataCount = useMemo(() => {
    return allChartData.map((entry) => entry.count);
  }, [allChartData]);

  const dateFormat = useMemo(() => {
    const firstDate = xData?.length > 0 ? xData[0] : new Date();
    const lastDate = xData?.length > 0 ? xData[xData.length - 1] : new Date();

    const yearDifference = lastDate.getFullYear() - firstDate.getFullYear();

    if (yearDifference > 1) {
      return "MMM yyyy";
    } else {
      return "MMM d";
    }
  }, [xData]);

  const formatDateLabel = useCallback((date) => {
    return format(date, dateFormat);
  }, [dateFormat]);

  const formatPriceLabel = (price) => {
    return formatTotalPrice(price);
  };

  const seriesToShow = useMemo(() => {
    return selectedSeries === "sales" ? "sales" : "count";
  }, [selectedSeries]);

  const xAxis = useMemo(() => {
    return [
      {
        data: xData,
        tickNumber: 5,
        scaleType: 'time',
        name: 'Date',
        min: xData.length > 0 ? xData[0] : null,
        max: xData.length > 0 ? xData[xData.length - 1] : null,
        valueFormatter: formatDateLabel,
      },
    ]
  }, [formatDateLabel, xData]);

  const yAxis = useMemo(() => {
    return [
      {
        name: selectedSeries === "sales" ? 'Total Sales' : 'Items',
        // min: 120,
        valueFormatter: selectedSeries === "sales" ? formatPriceLabel : null,
      },
    ];
  }, [selectedSeries]);

  const series = useMemo(() => {
    return [
      {
        id: seriesToShow,
        data: selectedSeries === "sales" ? yDataSales : yDataCount,
        name: selectedSeries === "sales" ? 'Total Sales' : 'Items',
        showMark: false,
        color: selectedSeries === "sales" ? "#5b75da" : "#27a17b",
        area: true,
      },
    ];
  }, [selectedSeries, seriesToShow, yDataCount, yDataSales]);

  const style = {
    '& .MuiLineElement-root': {
      strokeWidth: 2,
    },
    '& .MuiAreaElement-series-sales': {
      fill: 'url(#dotPattern)',
    },
    '& .MuiAreaElement-series-count': {
      fill: 'url(#dotPattern2)',
    },
  };

  const customize = {
    height: 600,
    legend: { hidden: true },
  };

  return (
    <>
      <LineChart
        sx={style}
        xAxis={xAxis}
        yAxis={yAxis}
        series={series}
        {...customize}
      >
        <Patterns />
      </LineChart>
    </>
  );
};

export default SalesChart;
