const PATHS = {
  HOME: "/",
  SIGN_IN: "/sign-in",
  REGISTER: "/register",
  SETTINGS: "/settings",
  PREMIUM: "/premium",
  PREMIUM_UNLOCKED: "/premium-unlocked",
  GUIDES: "/guides",
  FEE_CALCULATOR: "/poshmark-fee-calculator",
  POSHMARK_ASSISTANT: "/poshmark-assistant",
  PRODUCT_RESEARCH: "/product-research",
  LISTING_ASSISTANT: "/listing-assistant",
  COMPARE_LISTINGS: "/compare-listings",
  POST_GENERATOR: "/post-generator",
  CLOSET_REPORT: "/closet-report",
  RANK_CHECKER: "/listing-rank-checker",
  TOP_BRANDS: "/top-selling-brands-on-poshmark",
  TOP_SELLERS: "/top-sellers-on-poshmark",
  TOP_CATEGORIES: "/top-categories-on-poshmark",
  LISTING_ANALYZER: "/listing-analyzer",
  POSHMARK_LISTING_ANALYZER: "/listing-analyzer-poshmark",
  DEPOP_LISTING_ANALYZER: "/listing-analyzer-depop",
  MERCARI_LISTING_ANALYZER: "/listing-analyzer-mercari",
  EBAY_LISTING_ANALYZER: "/listing-analyzer-ebay",
  GRAILED_LISTING_ANALYZER: "/listing-analyzer-grailed",
  STYLE_GLOSSARY: "/style-glossary",
  SELLERS: "/seller/:username",
  BRAND: "/brand/:brand",
  CATEGORY: "/category/:category",
  CATEGORIES: [
    { name: "Shoes", path: "/category/shoes" },
    { name: "Tops", path: "/category/tops" },
    { name: "Pants & Jumpsuits", path: "/category/pants-and-jumpsuits" },
    { name: "Bags", path: "/category/bags" },
    { name: "Jackets & Coats", path: "/category/jackets-and-coats" },
    { name: "Jeans", path: "/category/jeans" },
    { name: "Dresses", path: "/category/dresses" },
    { name: "Sweaters", path: "/category/sweaters" },
    { name: "Accessories", path: "/category/accessories" },
    { name: "Shirts", path: "/category/shirts" }
  ],
  GUIDES_PATHS: [
    { name: 'How to Start Selling on Poshmark: A Guide for New Sellers', path: '/guides/how-to-start-selling-on-poshmark' },
    { name: 'How to Create Poshmark Listings that Sell', path: '/guides/how-create-poshmark-listings-that-sell' },
    { name: 'Guide to Identifying High Quality Clothing', path: '/guides/reseller-guide-to-identifying-high-quality-clothing' },
    { name: 'The Best Listing Template to Speed Up Your Poshmark Sales', path: '/guides/poshmark-listing-template' },
    { name: '6 Tips To Help You List Consistently on Poshmark', path: '/guides/how-to-list-consistently-on-poshmark' },
    { name: 'The Best Time to Share on Poshmark to Increase Listing Visibility', path: '/guides/the-best-time-to-share-on-poshmark' },
    { name: '5 Things Every Poshmark Reseller Should Do In 2023', path: '/guides/reseller-tips' },
    { name: 'My Poshmark Journey: Turning Clutter into Cash', path: '/guides/my-poshmark-journey' }
  ]
}

export default PATHS;