import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { lightTheme } from '../customTheme';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import Panel from '../components/Panel';
import { Box, Button, Container, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { colors } from '../customTheme';
import Skeleton from '@mui/material/Skeleton';
import { useParams } from 'react-router-dom';
import { getCategoryDetails } from '../api/client';
import { formatTotalPrice, formatName, formatNumberCompact, hyphenateName } from '../utils/helpers';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import NewsletterPanel from '../components/NewsletterPanel';
import PATHS from '../utils/paths';
import { useAuth } from '../contexts/AuthContext';

export const CategoryDetails = () => {
  const { category } = useParams();
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isPremium } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const result = await getCategoryDetails(category);
        setSummary(result);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [category]);

  const SkeletonRows = () => {
    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
      <Skeleton key={index} height={48} animation="pulse" />
    ));

    return <Container>{skeletonRows}</Container>;
  };

  const handleTryPremium = async () => {
    navigate(PATHS.PREMIUM);
  }

  const StatTable = ({ title, column1, column2, data, blur }) => {
    return (
      <Grid item xs={12} sm={6} lg={4}>
        <TableContainer
          component={Paper}
          elevation={0}
          variant="outlined"
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.12)', position: 'relative'
          }}
        >
          {blur && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                zIndex: 1,
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                Want more insights?
              </Typography>
              <Button elevation={0} variant="contained" color="primary" sx={{ mt: 1 }} style={{
                backgroundColor: colors.green, boxShadow: 'none',
              }}
                onClick={handleTryPremium}
              >
                Try Premium
              </Button>
            </Box>
          )}
          <Typography variant='body1' color="textSecondary" align='center' pt={2} pl={2} pr={2}>
            {title}
          </Typography>
          <Box sx={{ filter: blur ? 'blur(5px)' : 'none', zIndex: 0, userSelect: blur ? "none" : "auto" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{column1}</TableCell>
                  <TableCell align="right">{column2}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.x}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.x}
                    </TableCell>
                    <TableCell align="right">{row.y}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </Grid>
    );
  }

  const renderTables = () => {
    const topN = 10;

    return (
      <Grid container spacing={2}>
        <StatTable
          title={`Top brands by total quantity`}
          column1={"Brand"}
          column2={"Items Sold"}
          data={summary.topValuesByCount.slice(0, topN).map((row) => {
            return { x: formatName(row.value), y: formatNumberCompact(row.count) }
          })}
        />
        <StatTable
          title={`Top brands by total sales`}
          column1={"Brand"}
          column2={"Sales"}
          data={summary.topValuesBySales.slice(0, topN).map((row) => {
            return { x: formatName(row.value), y: formatTotalPrice(row.sales) }
          })}
        />
        <StatTable
          title={"Highest average prices"}
          column1={"Brand"}
          column2={"Average"}
          data={summary.topValuesByMedianPrice.slice(0, topN).map((row) => {
            return { x: formatName(row.value), y: formatTotalPrice(row.stats.median) }
          })}
        />
        <StatTable
          title={"Fastest time-to-sell"}
          column1={"Brand"}
          column2={"Days"}
          data={summary.topValuesByFastestSelling.slice(0, topN).map((row) => {
            return { x: formatName(row.value), y: row.averageTimeToSell?.toFixed(2) }
          })}
          blur={!isPremium}
        />
        <StatTable
          title={"Most popular sizes"}
          column1={"Size"}
          column2={"Count"}
          data={summary.topSizesByPopularity.slice(0, Math.min(topN, summary.topSizesByPopularity.length)).map((row) => {
            return { x: row.size, y: formatNumberCompact(row.popularity) }
          })}
          blur={!isPremium}
        />
        <StatTable
          title={"Most popular categories"}
          column1={"Category"}
          column2={"Items"}
          data={summary.topSubcategories.slice(0, topN).map((row) => {
            return { x: formatName(row.subcategory), y: formatNumberCompact(row.count) }
          })}
          blur={!isPremium}
        />
        <StatTable
          title={"Most popular departments"}
          column1={"Department"}
          column2={"Count"}
          data={summary.topDepartments.slice(0, Math.min(topN, summary.topDepartments.length)).map((row) => {
            return { x: row.department, y: formatNumberCompact(row.count) }
          })}
          blur={!isPremium}
        />
      </Grid>
    );
  }

  return (
    <div>
      <Helmet bodyAttributes={{ style: `background-color : ${colors.alabaster}` }} >
        <title>{category}</title>
        <meta name="description" content={`The latest sales data for ${category}. Total sales, average sales per day, total items sold and more!`} />
        <meta property="og:title" content={`Top-selling ${category} on Poshmark`} />
        <meta property="og:description" content="Uncover the best selling items in a Poshmark category" />
        <meta property="og:url" content={`https://poshwatch.io/category/${hyphenateName(category)}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ResponsiveAppBar />

      <Panel theme={lightTheme}>
        <Container maxWidth='md' style={{ paddingBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h4' align='center' style={{ fontFamily: "Libre Baskerville", paddingBottom: '20px'}}>
            { `The Best-Selling ${formatName(category)} on Poshmark by Brands`}
          </Typography>
          <Typography pt={1} pb={2} variant='body1' align='center' color="textSecondary">
            {`Insights into the fastest-selling items from the highly demanded brands on Poshmark.`}
          </Typography>
          <Typography pt={1} pb={2} variant='body1' align='center' color="textSecondary">
            {`Sales data for ${formatName(category).toLowerCase()} for the past month`}
          </Typography>
        </Container>
        <Container maxWidth='lg'>
          <div style={{ width: '100%' }}>
            {!loading && summary && renderTables()}
            {loading && <SkeletonRows />}
          </div>
        </Container>
      </Panel>
      <NewsletterPanel />
      <Footer />
    </div>
  );
};
