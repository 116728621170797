import React from 'react';
import { Container } from '@mui/material';
import BrandsPageDetails from './BrandsPageDetails';
import CategoryPageDetails from './CategoryPageDetails';
import SellersPageDetails from './SellersPageDetails';
import { lightTheme } from '../../customTheme';
import Panel from '../Panel';

const ToolDetailSection = ({ type, children }) => {

  const setDetailsType = () => {
    let contentType;
    if(type === "brands") {
      contentType = <BrandsPageDetails />
    } else if(type === "categories") {
      contentType = <CategoryPageDetails />
    } else if(type === "creators") {
      contentType = <SellersPageDetails />
    } else {
      contentType = <></>
    }
    return contentType;
  }

  return (
    <Panel theme={lightTheme}>
      <Container maxWidth='md' align='left'>
        { setDetailsType() }
        { children }
      </Container>
    </Panel>
  );
};

export default ToolDetailSection;
