import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { TwitterCard } from './TwitterCard.tsx';
import { tweets, pies } from './data.ts';
import { DataProvider } from './DataProvider.tsx';
import ChartPost from './ChartPost.tsx';

const PostGenerator: React.FC = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const renderContent = () => {
    if (type === 'tweet') {
      return (
        <DataProvider initialData={tweets}>
          <TwitterCard />
        </DataProvider>
      );
    } else if (type === 'pie') {
      return (
        <DataProvider initialData={pies}>
          <ChartPost chartType={type} />
        </DataProvider>
      );
    }
    return null;
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default PostGenerator;