import React, { useState } from 'react';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet-async';
import Panel from '../../components/Panel';
import { lightTheme } from '../../customTheme';
import { useAuth } from '../../contexts/AuthContext';
import { MyFirebase } from '../../api/my_firebase.ts';
import * as StripePayment from '../../api/stripe_paypment.ts';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  Button,
  TextField,
  CardContent,
  Card,
  Typography,
  Container
} from '@mui/material';

const Settings = () => {
  const { isPremium, email } = useAuth();
  const [loading, setLoading] = useState(false);

  const app = MyFirebase.getInstance().getApp();

  return (
    <div style={{ position: 'relative' }}>
      <Helmet >
        <title>Settings</title>
        <meta name="description" content={"Account Settings"} />
      </Helmet>
      <ResponsiveAppBar />
      <Panel theme={lightTheme}>
        <Container maxWidth='lg' style={{}}>
          <Card variant="outlined" style={{ minWidth: "400px" }}>
            <CardContent style={{
              padding: "30px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start'
            }}>
              <Typography variant="h5" style={{ marginBottom: "20px" }}>
                Profile Details
              </Typography>

              {email && <TextField
                sx={{ mt: 0, }}
                size="small"
                label="Email"
                variant="outlined"
                disabled
                value={email}
                style={{ marginBottom: "16px" }}
              />}
              {
                isPremium &&
                <LoadingButton variant="outlined" color="primary"
                  style={{
                    backgroundColor: '#f2f2f2',
                    textTransform: 'none',
                    marginBottom: "16px",
                    height: "40px"
                  }}
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    const url = await StripePayment.getPortalUrl(app)
                    window.open(url, '_blank');
                    setLoading(false);
                  }}
                >
                  Manage Membership
                </LoadingButton>
              }
              <Button
                variant="outlined"
                color="primary"
                style={{
                  textTransform: 'none',
                  height: "40px"
                }}
                onClick={() => {
                  MyFirebase.getInstance().signOut();
                }}
              >
                Logout
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Panel>
      <Footer />
    </div>
  );
};

export default Settings;
