import React from 'react';
import { styled, Card, Typography, TextField } from '@mui/material';
import PricingSvg from '../images/pricing.svg';
import useFeeCalculator from '../hooks/useFeeCalculator';
import { Helmet } from 'react-helmet-async';
import { colors, darkTheme } from '../customTheme';
import { ThemeProvider } from '@mui/material/styles';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';
import NewsletterPanel from '../components/NewsletterPanel';
import FeeCalculatorDetails from '../components/FeeCalculatorDetails';
import PATHS from '../utils/paths';

const CenteredContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 16,
  marginTop: 48,
});

const StyledCard = styled(Card)({
  borderRadius: 10,
  margin: '16px',
  padding: '42px',
  border: 'none',
  boxShadow: '0px5px5px0px rgba(0,0,0,0.1)',
});

const CenteredCard = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '600px',
  margin: '0 auto',
});

const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 16,
  paddingBottom: 0,
});

const Title = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
});

const canonicalUrl = `${window.location.origin}${PATHS.FEE_CALCULATOR}`;

const FeeCalculator = () => {
  const {
    emptyState,
    salePriceThreshold,
    sellerFeePercentage,
    sellerFee,
    cost,
    setCost,
    salePrice,
    setSalePrice,
    grossProfit,
    profitMargin,
  } = useFeeCalculator();

  return (
    <div style={{ minHeight: "800px" }}>
      <Helmet bodyAttributes={{ style: `background-color : ${colors.mineShaft}` }} >
        <title>Poshmark Fee Calculator - Calculate Your Profits Instantly</title>
        <meta name="description" content={"Determine your Poshmark fees and potential profits using our user-friendly and completely free Poshmark fee calculator."} />
        <meta property="og:title" content="Poshmark Fee calculator" />
        <meta property="og:description" content="Calculate Your Profits Instantly" />
        <meta property="og:url" content="https://poshwatch.io/poshmark-fee-calculator" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <ResponsiveAppBar />
      <CenteredContainer>
        <ThemeProvider theme={darkTheme}>
          <TitleContainer>
            <img src={PricingSvg} alt="Pricing" style={{ paddingRight: '8px', alignSelf: 'center' }} />
            <Title variant="h1" style={{ fontSize: '2rem', textAlign: 'center', alignSelf: 'center', fontFamily: "Libre Baskerville"}}>
              Poshmark Fee Calculator - Calculate Your Profits Instantly
            </Title>
          </TitleContainer>
          <Typography variant='h2' style={{ fontSize: '1.3rem', fontWeight: '400', paddingBottom: '8px', textAlign: 'center', maxWidth: '600px' }}>
            <p>
              Poshmark deducts a fee after a sale:
            </p>
            <p>
              • $2.95 for orders under <span style={{ background: 'rgba(255,255,255,0.1)' }}>${salePriceThreshold.toFixed(2)}</span>.
            </p>
            <p>
              • For sales above <span style={{ background: 'rgba(255,255,255,0.0)' }}>${salePriceThreshold.toFixed(2)}</span>, the fee is <span style={{ background: 'rgba(255,255,255,0.1)' }}>{sellerFeePercentage * 100}%</span>.
            </p>
          </Typography>
        </ThemeProvider>
        <CenteredCard>
          <StyledCard>
            <TextField
              size="small"
              label="Item Sale Price ($)"
              variant="outlined"
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (!isNaN(value)) {
                  setSalePrice(value);
                }
              }}
              fullWidth
              margin="normal"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
            <TextField
              size="small"
              label="Item Cost ($)"
              variant="outlined"
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (!isNaN(value)) {
                  setCost(value);
                }
              }}
              fullWidth
              margin="normal"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
            <Typography variant="h3" style={{
              fontSize: '1.5rem',
              fontWeight: '500',
              marginTop: '16px',
              paddingTop: '8px',
              paddingBottom: '8px'
            }}>
              Results
            </Typography>
            <Typography style={{ paddingBottom: '8px' }}>
              Seller Fee: ${sellerFee.toFixed(2)}
            </Typography>
            <Typography style={{ paddingBottom: '8px' }}>
              Cost of Goods Sold: ${cost.toFixed(2)}
            </Typography>
            <Typography style={{ paddingBottom: '8px' }}>
              Revenue: ${salePrice.toFixed(2)}
            </Typography>
            <Typography style={{ paddingBottom: '8px' }}>
              Gross Profit: {emptyState ? 'N/A' : (grossProfit < 0 ? '-' : '') + '$' + Math.abs(grossProfit).toFixed(2)}
            </Typography>
            <Typography style={{ paddingBottom: '8px' }}>
              Profit Margin: {emptyState ? 'N/A' : profitMargin.toFixed(2) + '%'}
            </Typography>
          </StyledCard>
        </CenteredCard>
      </CenteredContainer>
      < FeeCalculatorDetails/>
      <NewsletterPanel />
      <Footer />
    </div>
  );
};

export default FeeCalculator;