import bestTimeToShare from "./bestTimeToShare";
import resellerTips from "./resellerTips";
import myJourney from "./myJourney";
import howToStartSellingOnPoshmark from './howToStartSellingOnPoshmark';
import howToList from './howToList';
import howToIdentifyQuality from './howToIdentifyQuality';
import howToListConsistently from './howToListConsistently';
import poshmarkListingTemplate from './poshmarkListingTemplate';
import essentialResellerTools from './resellerEssentials';
import howToRank1inPoshmarkSearch from './howToRankHigherOnPoshmark';
import howToSource from './howToSource';

const reselling = [
  {
    title: 'How to Start Selling on Poshmark: A Guide for New Sellers',
    description: 'Learn how to start selling on Poshmark. A guide for new Poshmark sellers, covering benefits, mindset, and key steps for success.',
    slug: 'how-to-start-selling-on-poshmark',
    markdown: howToStartSellingOnPoshmark
  },
  {
    title: 'Top Time-Saving Tools for Resellers',
    description: 'Streamline your reselling buisness with must-have tools that save time and simplify business operations.',
    slug: 'top-tools-for-poshmark-resellers',
    markdown: essentialResellerTools,
  },
  {
    title: 'The Best Time to Share on Poshmark to Increase Listing Visibility',
    description: 'Boost Poshmark sales with our guide on optimal sharing times. Learn when to share for maximum visibility and engagement. Optimize your schedule now!',
    slug: 'the-best-time-to-share-on-poshmark',
    markdown: bestTimeToShare
  },
  {
    title: '5 Things Every Poshmark Reseller Should Do In 2023',
    description: 'Boost Poshmark sales in 2024: list daily, share your items, price strategically, send quick offers, focus on quality.',
    slug: 'reseller-tips',
    markdown: resellerTips
  },
];

const listing = [
  {
    title: 'How to Rank #1 in Poshmark Search',
    description: 'Learn the 3 key factors to rank your listings #1 in Poshmark search results and increase your sales.',
    slug: 'how-to-rank-1-in-poshmark-search',
    markdown: howToRank1inPoshmarkSearch
  },
  {
    title: 'How to Create Poshmark Listings that Sell',
    description: 'Learn how to list effectively on Poshmark. In this guide we cover SEO, titles, descriptions and pricing so you can sell faster.',
    slug: 'how-create-poshmark-listings-that-sell',
    markdown: howToList,
  },
  {
    title: 'The Best Listing Template to Speed Up Your Poshmark Sales',
    description: 'Fast-Track you Poshmark listing process with our listing template. Create listings that top search results, attract buyers, and boost sales.',
    slug: 'poshmark-listing-template',
    markdown: poshmarkListingTemplate
  },
  {
    title: '6 Tips To Help You List Consistently on Poshmark',
    description: '6 tips to help you overcome procrastination and maintaining a consistent listing schedule to boost your visibility and sales.',
    slug: 'how-to-list-consistently-on-poshmark',
    markdown: howToListConsistently
  },
]

const sourcing = [
  {
    title: 'How to Source Fast Selling High Value Items for Your Poshmark Closet',
    description: 'Learn top strategies for sourcing high-demand and high value items for your Poshmark closet.',
    slug: 'how-to-source-for-your-poshmark-closet',
    markdown: howToSource
  },
  {
    title: 'Guide to Identifying High Quality Clothing',
    description: 'This comprehensive guide for resellers provides tips to identifying high quality clothing to help your poshmark closet stand out.',
    slug: 'reseller-guide-to-identifying-high-quality-clothing',
    markdown: howToIdentifyQuality,
  }, 
]

const opinion = [
  {
    title: 'My Poshmark Journey: Turning Clutter into Cash',
    description: 'Transform your cluttered closet into profit on Poshmark. This guide offers tips for selling, crafting listings, and engaging with the community.',
    slug: 'my-poshmark-journey',
    markdown: myJourney
  }
]

const all = [...reselling, ...listing, ...sourcing, ...opinion];

export { all, reselling, opinion, listing, sourcing };