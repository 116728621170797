import React, { useEffect, useState, useMemo } from 'react';
import { lightTheme } from '../customTheme';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import Panel from '../components/Panel';
import { Avatar, Container, Chip, Link, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getSummary, getTopCategories } from '../api/client';
import { Helmet } from 'react-helmet-async';
import { colors } from '../customTheme';
import ToggleButtons from '../components/ToggleButtons';
import Skeleton from '@mui/material/Skeleton';
import { formatNumberCompact, categoryColors } from '../utils/helpers';
import Footer from '../components/Footer';
import PremiumBanner from '../components/PremiumBanner';
import NewsletterPanel from '../components/NewsletterPanel';
import ToolDetailSection from '../components/tool-content/ToolDetailSection';
import { useAuth } from '../contexts/AuthContext';

export const NameCell = ({ name, href, newTab }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Link
        style={{ textDecoration: 'none' }}
        href={href}
        target={newTab ? '_blank' : '_self'}
      >
        {name}
      </Link>
    </div>
  );
};

export const CustomChip = ({ showAvatar, name, count, index }) => {
  return <Chip
    avatar={
      showAvatar ? <Avatar
        style={{
          fontSize: 9,
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: categoryColors[name] ?? '#888',
        }}
      >
        {/* {category.name[0]} */}
        {formatNumberCompact(count)}
      </Avatar> : undefined
    }
    key={index}
    label={name}
    style={{
      marginRight: '4px',
      fontSize: 11,
      marginBottom: '4px',
    }}
  />
}

export const TopPage = ({ type, columns, title, subtitle, description, DetailsSection, href, toggleOptions, toggleDefault = "7d" }) => {
  const [data, setData] = useState([]);
  const [timeFrame, setTimeFrame] = useState(toggleDefault);
  const [loading, setLoading] = useState(false);

  const { isPremium } = useAuth();

  const handleRowTap = (params) => {
  };

  const onTimeFrameChanged = (_, value) => {
    setTimeFrame(value);
  }

  useEffect(() => {
    setLoading(true);

    if (type === "categories") {
      getTopCategories()
        .then((result) => {
          const categories = Object.keys(result).map((category, index) => {
            return {
              name: category,
              totalPrice: result[category].summary.totalSales,
              soldItemsCount: result[category].summary.totalCount,
              averageSalePrice: result[category].summary.totalSales / result[category].summary.totalCount,
              brands: result[category].topValuesBySales.map((item) => {
                return { name: item.value, count: item.sales };
              }),
            };
          });

          // Sort categories by totalCount
          categories.sort((a, b) => b.soldItemsCount - a.soldItemsCount);

          // Reassigning the id property based on the new order
          categories.forEach((category, index) => {
            category.id = index + 1;
          });

          setData(categories);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } else {
      getSummary(timeFrame, type)
        .then((result) => {
          const topBrands = result?.topBrands?.map((item, index) => {
            return {
              id: index + 1,
              name: item.name,
              totalPrice: item.total_price,
              soldItemsCount: item.sold_items_count,
              averageSalePrice: item.average_price,
              categories: item.categories,
              brands: item.brands,
            };
          });

          setData(topBrands);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }

  }, [timeFrame, type]);

  const SkeletonRows = () => {
    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
      <Skeleton key={index} height={48} animation="pulse" />
    ));

    return <Container>{skeletonRows}</Container>;
  };

  const filteredData = useMemo(() => {
    return isPremium ? data : data.slice(0, 23)
  }, [data, isPremium]);

  return (
    <div>
      <ResponsiveAppBar />
      <Helmet
        bodyAttributes={{ style: `background-color : ${colors.alabaster}` }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://poshwatch.io/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Panel theme={lightTheme}>
        <Container maxWidth='md' style={{ paddingBottom: '40px' }}>
          <Typography variant='h1' style={{ fontSize: "2.5rem", fontFamily: "Libre Baskerville" }} align='center'>{title}
          </Typography>
          <Typography align='center' color="textSecondary">{subtitle}
          </Typography>
        </Container>
        <Container maxWidth='lg' style={{ minHeight: "800px" }}>
          <div style={{ width: '100%' }}>
            <ToggleButtons value={timeFrame} onChange={onTimeFrameChanged} options={toggleOptions} />
            {!loading &&
              <DataGrid
                rows={filteredData}
                columns={columns}
                onRowClick={(params) => handleRowTap(params.row)}

                getRowClassName={(params) => {
                  if (!isPremium && params.id > (filteredData).length - 3) {
                    return 'blurred-row';
                  }
                  return '';
                }}
                sx={{
                  '& .blurred-row': {
                    filter: 'blur(4px)',
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    display: 'none',
                  },
                }}

                style={{
                  minWidth: '800px',
                }}
              />
            }
            {loading && <SkeletonRows />}
          </div>
          {!loading && isPremium === false && <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }}>
            <PremiumBanner />
          </div>}
        </Container>
      </Panel>
      <ToolDetailSection type={type}/>
      <NewsletterPanel />
      <Footer disableBgColor />
    </div>
  );
};
