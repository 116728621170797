import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Container,
  Link,
  Typography,
  Paper,
  List,
  Divider,
  IconButton,
} from '@mui/material';
import Panel from './Panel';
import { ResponsiveAppBar } from './ResponsiveAppBar';
import ReactMarkdown from 'react-markdown';
import { lightTheme, darkTheme } from '../customTheme';
import rehypeRaw from 'rehype-raw';
import Footer from '../components/Footer';
import NewsletterPanel from './NewsletterPanel';
import { Helmet } from 'react-helmet-async';
import { colors } from '../customTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const boxStyles = {
  plainBox: {
    pt: 6,
    border: '1px #eaeaea',
    bgcolor: 'transparent', // Assuming you want a transparent background by default
  },
  highlightedBox: {
    pt: 6,
    border: '1px #eaeaea',
    fontSize: '1 rem',
    bgcolor: colors.lightGrey,
  },
};

const DashedDivider = ({ variant }) => {
  return (
    <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
      <Divider
        variant={variant}
        sx={{ borderBottom: '1px dashed #eaeaea' }}
      />
    </div>
  );
};

const ArticleLink = ({ label, section, href, type }) => {
  const variants = {
    "h4": "body1",
    "h5": "body2",
  };

  const isH5 = type === "h5";

  const leftPadding = isH5 ? "10px" : "0px";
  const textOverflow = isH5 ? {
    whiteSpace: "nowrap",
    display: "block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  } : {};

  return (
    <Box>
      {section && <Typography color="textSecondary" style={{ fontSize: "0.8em" }}>{section}</Typography>
      }
      <Link
        variant={variants[type]}
        href={href}
        underline="hover"
        color={colors.mineShaft}
        style={{
          paddingLeft: leftPadding,
          ...textOverflow,
        }}
      >
        {label}
      </Link>
    </Box >);
}

function splitHeading(heading) {
  const [section, ...labelParts] = heading.split(' - ');
  const label = labelParts.join(' - ');
  const isChapter = section.startsWith('Chapter');

  return { section: isChapter ? section : undefined, label: isChapter ? label : heading };
}

function findHeadings({ markdown }) {
  const regex = /^(#+)\s+(.+)/gm;
  const headings = [];
  let match;

  while ((match = regex.exec(markdown)) !== null) {
    const headingType = match[1];
    const text = match[2];
    const { section, label } = splitHeading(match[2]);
    const slug = getSlug(text);
    const type = `h${headingType.length}`;

    headings.push({ section, label, slug, type });
  }

  return headings;
}

const TableOfContents = ({ markdown }) => {
  const [maxHeight, setMaxHeight] = useState(window.innerHeight - 100);
  const validTypes = ['h4', 'h5'];
  const headings = findHeadings({ markdown }).filter(h => validTypes.includes(h.type));

  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(window.innerHeight - 100);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Paper elevation={0} style={{ position: 'sticky', top: 20 }}>
      {/* <Box sx={{ p: 2, border: '1px dashed #eaeaea' }}> */}
      <Box sx={{ p: 2, border: '1px #eaeaea', maxHeight: `${maxHeight}px`, overflowY: 'auto' }}>
        <Typography variant="h2" fontWeight="bold" gutterBottom style={{ fontSize: "1.5rem", fontFamily: "Libre Baskerville", }}>
          Contents
        </Typography>
        <List>
          {headings.map((heading, index) => {
            const variant = heading.type === "h5" ? "middle" : "full";

            return (
              <React.Fragment key={index}>
                <ArticleLink label={heading.label} section={heading.section} href={`#${heading.slug}`} type={heading.type} />
                {index !== headings.length - 1 && <DashedDivider variant={variant} />}
              </React.Fragment>);
          })}
        </List>
      </Box>
    </Paper>
  );
};

function parseMarkdownSections(markdownText) {
  const sections = markdownText.split(/\s*~~~~\s*/);
  const result = [];

  for (const section of sections) {
    const [header, ...contentLines] = section.trim().split('\n');
    const [theme, color] = header.split(',');
    const markdown = contentLines.join('\n').trim();

    if (markdown) {
      result.push({ theme, color, markdown });
    }
  }

  return result;
}

const flatten = (text, child) => {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
};

const getSlug = (text) => text.toLowerCase().replace(/\W/g, '-');

const renderH = (props) => {
  const { children } = props;
  const { tagName } = props.node;
  const textAlign = ['h1', 'h2', 'h3'].includes(tagName) ? 'center' : 'left';

  const text = React.Children.toArray(children).reduce(flatten, '');
  const slug = getSlug(text);

  return <Typography
    fontFamily={"Libre Baskerville"}
    fontWeight={"bold"}
    id={slug}
    variant={tagName}
    textAlign={textAlign}
    marginBottom={3}>
      {text}
    </Typography>
}

const renderLink = (props) => {
  return <Link underline='always' target="_blank" href={props.href}>{props.children}</Link>
}

const renderP = (props) => {
  return <Typography variant="body1" lineHeight={1.7} marginBottom={2}>{props.children}</Typography>
}

const renderImg = (props) => {
  const { src, alt } = props;

  const noBorder = alt.includes("noBorder");

  return <Container maxWidth='md' style={{
    marginTop: 40,
    marginBottom: 40,
  }}>
    <img
      src={src}
      alt={alt}
      style={{
        maxWidth: '100%', // Fit the image inside the container
        height: 'auto', // Maintain aspect ratio
        // borderRadius: 14, // Rounded corners for the image
        display: 'block', // Remove any inline styling that affects spacing
        margin: '0 auto', // Center the image horizontally
        boxShadow: noBorder ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)', // Drop shadow
        // border: '2px solid #2e2e2e', // 2px solid black border
      }}
    />
  </Container>
}

const renderBlockQuote = (props) => {
  const { children } = props;
  console.log("children", children);

  const childArray = Array.isArray(children) ? children : [children];

  const handleCopyText = () => {
    const text = childArray.flatMap(child => {
      if (Array.isArray(child.props?.children)) {
        return child.props.children.join('\n');
      }
      return child.props?.children || '';  // Handle undefined children
    }).join('\n');

    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const renderChildren = (inputChildren) => {
    return inputChildren.map((child, index) => {
      if (typeof child === 'string') {
        // Handle strings directly (likely text nodes)
        return <Typography key={index} style={{ fontSize: "1.1em", whiteSpace: 'pre-wrap', fontFamily: "monospace" }}>{child}</Typography>;
      } else {
        // Recursively process children of this child
        return (
          <Typography key={index} style={{ fontSize: "1.1em", paddingBottom: "8px", fontFamily: "monospace" }}>
            {child.props && child.props.children ? renderChildren(Array.isArray(child.props.children) ? child.props.children : [child.props.children]) : null}
          </Typography>
        );
      }
    });
  };

  const elements = renderChildren(children);

  return (
    <Container
      style={{
        position: 'relative',
        marginTop: 40,
        marginBottom: 40,
        paddingTop: 20,
        paddingBottom: 20,
        background: colors.alabaster,
      }}
    >
      {elements}
      <IconButton
        style={{ position: 'absolute', top: 5, right: 5 }}
        onClick={handleCopyText}
        aria-label="Copy Text"
      >
        <FileCopyIcon />
      </IconButton>
    </Container>
  );
};


// const renderBlockQuote = (props) => {
//   const { children } = props;

//   const handleCopyText = () => {
//     const text = children.at(1).props.children;
//     navigator.clipboard.writeText(text).then(() => {
//       console.log('Text copied to clipboard');
//     }).catch(err => {
//       console.error('Failed to copy text: ', err);
//     });
//   };

//   const elements = children.map((c, index) => {
//     return (
//       <Typography key={index} style={{ fontSize: "1.2em", paddingBottom: "8px", fontFamily: "monospace" }}>
//         {c.props?.children}
//       </Typography>
//     );
//   });

//   return (
//     <Container
//       style={{
//         position: 'relative',
//         marginTop: 40,
//         marginBottom: 40,
//         paddingTop: 20,
//         paddingBottom: 20,
//         background: colors.alabaster,
//       }}
//     >
//       {elements}
//       <IconButton
//         style={{ position: 'absolute', top: 5, right: 5 }}
//         onClick={handleCopyText}
//         aria-label="Copy Text"
//       >
//         <FileCopyIcon />
//       </IconButton>
//     </Container>
//   );
// };

const renderLists = (props) => {
  const { children } = props;

  return <li>
    <Typography>{children}</Typography>
  </li>
}

const MarkdownBlog = ({ path, title, description, markdown }) => {

  const content = parseMarkdownSections(markdown);
  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <div>
      <Helmet >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`${window.location.origin}${path}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`${window.location.origin}${path}`} />
      </Helmet>
      <ResponsiveAppBar />
      <Container style={{ marginBottom: "20px" }}>
        <Grid container spacing={2}>
          {isMd && <Grid item xs={3} mt={25}>
            <TableOfContents markdown={markdown} />
          </Grid>}
          <Grid item xs={isMd ? 9 : 12}>
            {content.map((item, index) => (
              <Panel key={index}
                theme={item.theme === 'dark' ? darkTheme : lightTheme}
                backgroundColor={item.color === colors.lightGrey ? colors.white : item.color}
                paddingSize='xxs'>
                <Box sx={item.color === colors.lightGrey ? boxStyles.highlightedBox : boxStyles.plainBox}>
                  <Container maxWidth='md'>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        h1: renderH,
                        h2: renderH,
                        h3: renderH,
                        h4: renderH,
                        h5: renderH,
                        h6: renderH,
                        a: renderLink,
                        p: renderP,
                        img: renderImg,
                        li: renderLists,
                        blockquote: renderBlockQuote
                      }}>
                      {item.markdown}
                    </ReactMarkdown>
                  </Container>
                </Box>
              </Panel>
            ))}
          </Grid>
        </Grid>
      </Container>

      <NewsletterPanel />
      <Footer disableBgColor />
    </div>
  );
}

export default MarkdownBlog;
