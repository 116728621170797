import React, { useState } from 'react';
import { MyFirebase } from '../api/my_firebase.ts';
// import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleLogo from './svgs/GoogleLogo.js';
// import AppleIcon from '@mui/icons-material/Apple';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const SignInCard = ({ onSignIn, register = false } = { onSignIn: () => { } }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleGoogleSignIn = async () => {
    try {
      await MyFirebase.getInstance().signInWithGoogle();
      if (onSignIn) onSignIn();
      console.log("Successfully signed in with Google");
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleFacebookSignIn = async () => {
    try {
      await MyFirebase.getInstance().signInWithFacebook();
      if (onSignIn) onSignIn();
      console.log("Successfully signed in with Facebook");
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleAppleSignIn = async () => {
    try {
      await MyFirebase.getInstance().signInWithApple();
      if (onSignIn) onSignIn();
      console.log("Successfully signed in with Apple");
    } catch (e) {
      console.error(e);
    }
  };

  const handleEmailSignIn = async () => {
    try {
      const result = await MyFirebase.getInstance().signInWithEmail(email, password);
      if (result) {
        if (onSignIn) onSignIn();
        console.log("Successfully signed in with email and password");
      } else {
        setError("Invalid email or password.");
      }
    } catch (e) {
      setError("Failed to sign in with email and password.");
      console.error(e);
    }
  };

  const handleEmailRegistration = async () => {
    try {
      const result = await MyFirebase.getInstance().registerWithEmail(email, password);
      if (result) {
        if (onSignIn) onSignIn();
        console.log("Successfully registered with email and password");
      } else {
        setError("Invalid email or password.");
      }
    } catch (e) {
      setError("Failed to register with email and password.");
      console.error(e);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Button
          onClick={handleGoogleSignIn}
          variant="contained"
          color="primary"
          fullWidth
          style={{
            boxShadow: 'none',
            backgroundColor: '#f2f2f2',
            color: 'black',
            textTransform: 'none',
            marginBottom: '10px',
            height: '40px'
          }}
          startIcon={<GoogleLogo />}
        >
          Continue with Google
        </Button>
        {/* <Button
          onClick={handleFacebookSignIn}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<FacebookIcon />}
          style={{ backgroundColor: '#3e5a98', textTransform: 'none', height: '40px', marginBottom: '10px' }}
        >
          Continue with Facebook
        </Button>
        <Button
          onClick={handleAppleSignIn}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<AppleIcon />}
          style={{ textTransform: 'none', height: '40px' }}
        >
          Continue with Apple
        </Button> */}

        <Box paddingBottom={2} paddingTop={1}>
          <Divider textAlign="center">or continue with email</Divider>
        </Box>

        <TextField
          size="small"
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          size="small"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          onClick={register ? handleEmailRegistration : handleEmailSignIn}
          variant="contained"
          color="primary"
          fullWidth
          style={{
            boxShadow: 'none',
            textTransform: 'none',
            marginBottom: '10px',
            height: '40px'
          }}
        >
          {register ? "Register with Email" : "Sign In with Email"}
        </Button>

        {error && <Alert severity="error">{error}</Alert>}
      </div>
    </div>
  );
}

export default SignInCard;