import React from 'react';
import { lightTheme } from '../customTheme';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { Box, Container, Divider, List, Typography, Link } from '@mui/material';
import NewsletterPanel from '../components/NewsletterPanel';
import Panel from '../components/Panel';
import { useTheme } from '@mui/material/styles';
import { reselling, opinion, listing, sourcing } from '../blog';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import PATHS from '../utils/paths';

const SectionList = ({ title, blogs }) => {
  const theme = useTheme();

  return (
    <Box marginBottom={2}>
      <Typography variant='h5' marginBottom={1} fontFamily={"Libre Baskerville"}>{title}</Typography>
      <Divider />
      <Box display="flex" flexDirection="row" marginTop={1}>
        <Box flex={1}>
          <List>
            {blogs.map(blog => (
              <Box marginBottom={2} key={blog.slug}>
                <Link href={`/guides/${blog.slug}`} underline="hover" style={{ color: theme.palette.text.primary }}>
                  {blog.title}
                </Link>
              </Box>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

const Guides = () => {

  const renderHeader = () => {
    return (
      <Container maxWidth='md'>
        <Typography 
          variant="h1" 
          style={{ fontSize: "2.5rem", marginBottom: "30px", fontFamily: "Libre Baskerville" }} 
          align="center">
            The Guides to Help You <em>Sell and Profit</em>.
        </Typography>
        <Typography 
          variant="body1" 
          align="center" 
          color="textSecondary"
        >
          Browse the latest Poshwatch blog posts about listing, sourcing and reselling on Poshmark to help you hit and exceed your sales goals.
        </Typography>
      </Container>
    )
  };

  return (
    <div>
      <Helmet >
        <title>Poshmark Reselling Guides</title>
        <meta name="description" content={"Elevate your Poshmark reselling game with our expert guides. From sourcing to listing, we've got you covered."} />
        <meta property="og:title" content="Poshwatch Reseller Guides" />
        <meta property="og:description" content="All the information you need to increase your sales." />
        <meta property="og:url" content="https://poshwatch.io/guides" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`${window.location.origin}${PATHS.GUIDES}`} />
      </Helmet>
      <ResponsiveAppBar />
      <Panel theme={lightTheme}>
        { renderHeader() }
      </Panel>
      <Panel style={{ backgroundColor:"white" }}>
        <Container maxWidth="md">
          <SectionList title="Learn Reselling" blogs={reselling} />
          <SectionList title="Listing" blogs={listing} />
          <SectionList title="Sourcing" blogs={sourcing} />
          <SectionList title="My Journey" blogs={opinion} />
        </Container>
      </Panel>
      <NewsletterPanel />
      <Footer />
    </div>
  );
};

export default Guides;
