import * as React from 'react';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(1),
    border: 0,
  },
}));

const defaultOptions = [
  { value: "1d", label: '1D' },
  { value: "3d", label: '3D' },
  { value: "7d", label: '7D' },
  { value: "1m", label: '1M' },
];

export default function ToggleButtons({ value, onChange, options = defaultOptions, width = 50 }) {
  const handleToggleChange = (_, newValue) => {
    if (newValue !== null) {
      onChange(_, newValue);
    }
  };

  return (
    <div>
      <StyledToggleButtonGroup
        size="small"
        value={value}
        exclusive
        onChange={handleToggleChange}
        aria-label="text alignment"
      >
        {options.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            disableRipple
            style={{
              borderRadius: '40px',
              width,
              textTransform: 'none',
            }}
          >
            {option.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </div>
  );
}
