import { colors } from '../customTheme';
import ListingAnalyzer from '../images/how_to_list/analyzer.png';

const poshmarkListingTemplate = `
~~~~light,${colors.white}
# The Best Listing Template to Speed Up Your Poshmark Sales

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated April. 10, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Tommy
</div>
\n&nbsp;\n

#### Introduction

Hello future top seller! Today we’re serving up a listing template that will ensure you create an A + one every single time. Akin to creating a physical shop front with a captivating window display that beckons potential buyers to enter and spend. 

A well-crafted listing with a strong template serves as a gateway to increased visibility, enhanced sales, and  most importantly, buyer trust. Let’s proceed to a foolproof strategy to create listings that sell. 

Stick with us till the end, we’ve got a template for you to use in your own listings so you can start earning more ASAP.

~~~~light,${colors.white}
#### Understanding your buyers

To master the art of resale, it's essential to understand the audience that frequents the platforms as well as the one’s that you’re attracting.These platforms attract a diverse array of shoppers, ranging from avid vintage enthusiasts, fashion-forward trendsetters to the casual shopper. 

Depending on the brands and styles you’re listing, you will need to adjust to the mindset of the buyer. Are they trend based Gen Z? Are they looking for old and trusted brand stables that are going to complete their wardrobe? Use your discernment and adjust accordingly. 

With that said, all shoppers seek authenticity, uniqueness, and value in their purchases. Therefore, no matter the tone, tailoring your listings to cater to these expectations is paramount and should be the baseline of all your listings.

Crafting a template that resonates with the platform's audience can significantly enhance sales. Consider incorporating elements that highlight the authenticity and provenance of your pieces, such as detailed descriptions of materials, era, and any unique features. 

Additionally, utilizing keywords and tags relevant to the niche of the garments can improve discoverability and attract the right audience to your listings.


~~~~light,${colors.white}
##### Components of a Successful Listing

Once you’ve got your target audience in mind, it’s time to shift focus to the framework of the listing.  A successful listing consists of various components, each playing a crucial role in capturing the attention of potential buyers and encouraging them to make a purchase. 

Let's break down these components and explore how to optimize them for maximum impact:

**Title:**

Your listing's title is the first thing potential buyers see, making it crucial for grabbing their attention. Incorporate relevant keywords, such as brand names, style, and era, to improve search visibility and attract the right audience.

**Images:**

High-quality images are essential for showcasing the details and condition of your vintage clothing items. Utilize natural lighting and multiple angles to provide a comprehensive view, instilling confidence in prospective buyers.

**Description:**

The description serves as your opportunity to provide additional context about the item. Be thorough yet concise, highlighting key features, measurements, and any imperfections. Transparency builds trust with buyers and reduces the likelihood of returns or disputes.

**Pricing:**

Setting the right price is a delicate balance between maximizing profit and attracting buyers. Research comparable listings to gauge market value, considering factors such as rarity, condition, and demand. Competitive pricing can expedite sales and garner positive feedback from satisfied customers.

~~~~light,${colors.white}
#### Incorporating SEO

The most common way for listings to be found are via Poshmark search results and Google search results. Therefore, you want your listing to closely match relevant keywords by strategically placing relevant keywords in the Title and description of your listing in a way that flows seamlessly. Here are some tips:

**Title:**

Include the most important key terms that accurately describe your item and are commonly searched for by potential buyers. Ensure the title is descriptive but concise, incorporating relevant keywords without keyword stuffing.

**Description:**

Integrate additional keywords throughout the description, focusing on providing detailed information  that will help the buyer make an informed purchasing decision while incorporating relevant terms. 

Prioritize readability, coherence and relevance and avoid words like cute, pretty etc. As they are not useful.

**Tags and Attributes:**

Utilize platform-specific tags and attributes to further optimize your listing for search. Include relevant keywords in these fields to improve visibility and attract the right audience.

**Variations:**

If applicable, incorporate variations of keywords related to color, size, or style to capture a broader range of search queries.

By incorporating SEO keywords, watch your listing float to the top every time.


~~~~light,${colors.white}
#### The Ideal Listing Template

Finally, let’s streamline the process of posting new items, ensuring consistency and efficiency across your inventory. It should include placeholders for essential details, such as item description, measurements, and pricing, allowing you to input specific information quickly. For your understanding we’re breaking down the framework. 

Benefits of utilizing a listing template include:

**SEO Optimization:**

A well-designed listing template will include  all the necessary details and keywords allowing it to be optimized for search engines. This will help your listings rank higher in search results and increase visibility.

**Consistency:**

Maintaining a consistent format across your listings enhances brand identity and professionalism, making it easier for buyers to navigate your shop.

**Efficiency:**

By predefining the structure of your listings, you can save time and effort when posting new items, allowing you to focus on sourcing, curating, and marketing your vintage clothing collection.

**Accuracy:**

A template helps ensure that vital information is not overlooked or omitted, reducing the likelihood of errors and misunderstandings with buyers and ultimately reduce complaints and returns.

\n&nbsp;\n

##### Templates

Copy the highlighted text below to use in your listing then fill in the details will info from the garment tag, original retailer listing or our Glossary of garment terms below.

**Title:**

Start with the most important selling factors Condition and Brand then add the remaining relevant keywords. *Note** It is only important to indicate materials if you need to distinguish between faux and genuine materials like Leather, Silk, Wool etc.

> Condition | Brand Name | Style Name | Color | Keywords/Materials | Size

**Example:**

> NEW Coach Moto Jacket Brown 100% Suede Leather Size S

\n&nbsp;\n
**Description:**

I like to start by re-adding the title then follow with the additional details. Most of which you should be able to find from the materials tag or by looking for the original listing from the retailer online.

> Condition | Brand Name | Style Name | color | Keywords/Materials | Size
>
> Detailed Condition:
> NWT / NEW Condition, Flaws, Fit
>
> Materials:
> Shell + Lining
>
> Approximate Measurements:
> Waist / Hips / Sleeve length / Inseam / Rise / Leg length / Body length etc.
>
> Care instructions:
> Dry clean / Machine wash only / Hand wash etc.
>
> Additional details:
> Neckline / Zippers / Sleeve length / Pockets / Lining / Collared & Collar style / Buttons / Silhouette /  Fabric / Print / Length / Hem …etc.
>
> Occasion: (Optional)
> Cruise / Vacation / Casual / Semi-Casual / Cocktail / Evening
>
> Details about your home that might affect the garment:
> Smoke free / Pet free

##### Example 1: New condition

> NEW Coach Moto Jacket Brown Suede Leather Size S.
>
> Excellent condition with no flaws and fits true to size.
>
> Short Moto Jacket
> Long Sleeve with zipped cuffs
> Asymmetrical front-zip closure
> Side and front pockets
> Round neckline
>
> Dry clean only
>
> Body length: Regular 23"
> Sleeve length from center back: Regular 30"
>
> Material Body: 100% Suede leather
> Lining: 100% Rayon

##### Example 2: Used condition

> Banana Republic Jacket Black Suede Leather Size 8
>
> Good condition with minor flaws on sleeve. Semi-fitted
>
> Waist length trucker Jacket
> Front button closure
> Side pockets
> Collared neckline
>
> Dry clean only
>
> Body length: Regular 21.5"
> Sleeve length from center back: Regular 32"
>
> Material Body: 100% Suede leather
> Lining: 100% Rayon

~~~~light,${colors.white}
#### Tools and Resources

Feeling like you need to do some more in depth reading before taking the plunge? We’ve written a blog that goes into the intricacies of how to list like top sellers. [How to List on Poshmark](https://poshwatch.io/guides/how-create-poshmark-listings-that-sell)

Need to evaluate or touch up your old listings? We’ve also got you covered, try our free analyzer tool: [Poshmark Listing Analyzer](https://poshwatch.io/listing-analyzer-poshmark) It will evaluate your listing and tell you what you are missing to help you improve it.

![Example of listing titles on Poshmark](${ListingAnalyzer})


~~~~light,${colors.white}
#### Conclusion

In conclusion, mastering the art of resale  requires a combination of understanding your platform's audience, optimizing listing components, and implementing efficient listing templates. 

By embracing these strategies, you can elevate your online presence, attract discerning buyers, and achieve success in the competitive world of online resale.
`;

export default poshmarkListingTemplate;