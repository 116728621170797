import { colors } from '../customTheme';
import LowCOGS from '../images/how_to_source/low_cogs.png';
import CityDataMap from '../images/how_to_source/city_data_map.png';
import TimeManagement from '../images/how_to_source/time_management.png';
import HighValueSales from '../images/how_to_source/high_value_sales.png';
import CityDataZipcode from '../images/how_to_source/city_data_zipcode.png';
import ProductResearchJudyBlue from '../images/how_to_source/product_research_judy_blue.png';


const howToSource = `
~~~~light,${colors.white}
# How to Source Fast Selling High Value Items for Your Poshmark Closet

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Jul. 20, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

#### Introduction

Indulge me for a moment, if you will — the root of a tree anchors and nourishes the plant, and a car's engine powers its movement, right? 

They work tirelessly behind the scenes, creating the foundation for functions we often take for granted. Similarly, the overall success of a Poshmark business hinges on one thing; Sourcing. Your customers don’t see the work behind it or even understand it, but that’s the beauty of sourcing.

~~~~light,${colors.white}
#### Chapter 1 - Understanding the Basics of Sourcing

Sourcing for resale is the process of finding and acquiring inventory at a low cost and that can be resold for a decent profit.

Not just any items, but products with high demand to attract the right buyers to help your business grow and succeed.

Get ready to take notes, I'll be sharing all the gems on sourcing.

~~~~light,${colors.white}
##### 1.1 - Buying Right: How to Source Fast-Selling, High-Value Products

Sourcing is every reseller's favorite activity; it's our catnip.

When you step into a thrift store, your pulse quickens as excitement surges through you. The distinctive mix of scents—old leather, books, pre-loved clothes, and vintage fabric fills the air as you take a moment to breathe it all in.

Then, as you grab a cart, your hands tremble with the eagerness to uncover the gold nuggets waiting for you among the aisles, and the thrill of discovery tingles and raises every hair down your spine. 

But even in the grips of excitement you must always remember, your hard-earned money is on the line.

Therefore, it is crucial that you don't get carried away picking up every and anything. Focus on stocking trending or high-demand items, that’s a fundamental truth of retail and business.


Here’s how you can ensure you’re buying right:

**Check Sold Listings:** 

I know I sound like a parrot, but I can't say this one enough.

Always check sold listings.

On Poshmark and eBay, look at the sold listings versus active listings. For instance, if there are 2000 Vuori leggings sold, and only 88 listed, it just might be an item worth investing in. This indicates high demand and lower competition.

This approach helps you make informed decisions, ensuring quicker sales and better cash flow.

**Ignore personal preferences:** 

You might not want to hear this, but when 'buying right,' personal preferences have to take a back seat. While having good taste and opinions is valuable, market data is the best guide for sourcing.

**Analyze Market Data:** 

Use tools and resources that provide market insights. Look at what top resellers are listing and selling. Tools like Terapeak for eBay can offer valuable data on what's trending.

**Network with Other Resellers:** 

Join reseller communities and forums. 

Networking can provide insights into what’s selling well and emerging trends. You can also exchange sourcing tips and strategies.

**Stay Updated on Fashion and Market Trends:** 

Follow fashion and reseller blogs, influencers, and retail news to stay ahead of trends. This helps you anticipate what will be in demand next season.


~~~~light,${colors.white}
##### 1.2 - Example of How to Buy Right

You’re in the thrift store, and you spot a bin overflowing with potential — different pairs of jeans from various brands, sizes, colors, and prices. 

How do you know what to pick up?

They all seem great, but maybe this is your first time sourcing or you have been burned before by picking up a brand you thought would do well. Instead, the piece you bought sat in your closet for so long that you wanted to ask it to start paying rent.

We don’t want that, do we? If you pick up a pair of boot-cut Judy Blue jeans in size 14 for about $9, here are the steps to determine if it’s worth adding to your inventory:

1. **Familiarize Yourself with Research Tools and the Brand:** 

    In the past, you would have to scroll tirelessly through sold listings on Poshmark to see how similar items sold. Now, you can use our [Product research](https://poshwatch.io/product-research) tool, or our handy data resources to learn more about specific brands and categories [Judy Blue](https://poshwatch.io/brand/judy-blue) and [Jeans](https://poshwatch.io/category/jeans) in this case.

2. **Find the Style Name:** 

    To look at comparable prices for your inventory, first nail down the style name. Some brands put style names or numbers on their labels, but others make it trickier. If you can’t find it easily:

    *[Use Google Lens](https://lens.google/):*

    This free app allows you to take a picture of an item and search for similar images to find the original brand or style name.

    *Use Fabric Content and Keyword Descriptors:*

    If Google Lens doesn't help, try keyword descriptors like “Cotton Tencel blend blue stripe V-neck shirt.” Some brands list items under keywords like this.

3. **Check Sold Listings for Comparison Research:** 

    Once you have the style name, you’ll see original and reseller pricing for the garment pop up on Google. For more in-depth analysis, let's use our Product Research tool, which allows you to input a style name and get instant details like:

    - Average price
    - Full price range
    - Links to recently sold listings
    - The average time it takes to sell
    - Total number of listings currently on Poshmark
    - Breakdown of sizing, colors, and the condition of available items

![Example of our product research tool](${ProductResearchJudyBlue})

Here, you can see that for the price of $9, if the jeans we are considering sell at an average price of $30, we would make a profit of 21 dollars, which takes our rate to 70%. Not bad!

And, only an avg of 12 days to sell, perfecto.

Use all this information to decide whether an item is worth including in your inventory. The goal is to determine if Poshmark is oversaturated with the item you are considering or if the demand is low, so that you can avoid those items and focus on better performers.


~~~~light,${colors.white}
#### Chapter 2 - Where to Source Inventory

If you’re new to reselling, the best place to start is right in your own closet. 

You might be surprised by the potential of those items you no longer wear. Check with family and friends too — see if they have anything they’re ready to part with. And don’t be shy about asking; this approach allows you to practice your reselling skills without any upfront financial investment.

Once you’re ready to move beyond your network, there are two main avenues for sourcing inventory: online and in person.

Online platforms are convenient, but the best profit margins often come from in-person finds. Ok, but where should you go, and how can you make this process easier? 

It all comes down to two crucial factors:

1. **Your Business Model:**

    Decide whether you want to focus on higher-end products or items with a lower cost of goods. Your choice will guide where you source and what you look for.

2. **Specialization:** 

    Think about the categories you want to specialize in, such as handbags, athleisure, or unique trinkets. Knowing the items you want will save you time and energy because, instead of looking all around, you'll have a better idea of where to look for specific items.

Here's a curated list of sourcing hotspots that will help with your routine inventory quests:

~~~~light,${colors.white}
##### 2.1 - Sourcing Locations

- **Goodwill (local and online):** You can find a bit of everything here, including clothing, electronics, and home goods. It's perfect for finding vintage clothing and unique home decor at affordable prices.

- **Thrift stores:** Similar to Goodwill, they offer a broad spectrum of items. Excellent for discovering one-of-a-kind pieces, vintage clothing, and occasionally, high-end brands at a fraction of the cost.

- **Local and Online Consignment Shops:** For higher-end clothing, accessories, and sometimes furniture, they're usually in pretty good shape and marked by condition, saving you some work in your listing. 

    Expect quality items from reputable brands.

- **Yard/Estate/Garage sales:** For those who enjoy the thrill of the hunt! Those vintage and quirky items have been hidden in personal collections, sometimes for decades. 

    Usually, deeply discounted goods, where you have the upper hand as the sellers are extremely eager to get rid of items to free up space rather than selling purely for profit. You’re practically doing them a favor by taking the items off their hands.

- **Church resale shops:** Similar to other thrift stores but often smaller and with a community focus. Best for clothing, books, and household items, with proceeds usually going to charitable causes.

- **Flea markets:** The spot for vintage, antiques, home goods, and collectibles. They are best for finding unique pieces with history and character at negotiable prices, and most towns have a little flea market, making them accessible.

- **Facebook Marketplace/Craigslist:** This option probably has the widest selection, including plants, clothing, shoes, furniture, home goods, appliances, and electronics. 

    The best part? If you're quick enough, you can haggle directly with sellers and sometimes even snag decent-quality items in the ‘free’ section. 

    The downside of dealing with the overwhelming number of flaky responses “Hi, is this still available?” – Yes, Sandra, it’s still available.

    It's a small price to pay for decent finds.

- **Storage Unit Auctions:** These are unpredictable but can yield bulk quantities of items ranging from furniture to collectibles. They are best for those looking to resell and willing to take a gamble on the unknown.

- **OfferUp/LetGo:** Similar to Facebook Marketplace, it offers a broad selection of items. It is best for local deals on a wide range of products, from furniture to electronics, with user-friendly platforms.

- **Liquidation Stores:** These are best for finding new, overstocked, and closeout items in bulk. You can find everything from clothing and accessories to electronics and home goods, often at significantly reduced prices. 

    With this option, you will need extra storage.

- **Clearance Sales and Outlet Store Sales:** Ideal for new, branded items at discounted prices and current fashion and brand-name goods without the full retail markup. 

    These options might not have the lowest prices, but they are still worth taking a peek at.

- **Retail Clearance Sections:** (e.g., Steinmart, TJMaxx, Ross, Winners, Nordstrom Rack, Marshalls) Best for brand-name clothing, accessories, and home goods at reduced prices. 

    They are similar to outlets, so they may not be the cheapest sourcing options, but they are great for adding high-quality, fashionable items to your inventory.

~~~~light,${colors.white}
##### 2.2 - How to Find Good Thrift Stores and Other Sourcing Spots

To optimize your sourcing strategy, focus on high-income areas, which often yield better quality items in thrift stores and garage sales. Here's a step-by-step guide to using City Data for this purpose:

1. **Visit City Data:**
Go to [City-Data](https://www.city-data.com/).

2. **Enter the City Name:**
Type in the name of a city you want to explore, such as Houston.

3. **Scroll to the Map:**
Scroll down until you see the map that highlights various income levels.

![city data map](${CityDataMap})

4. **Identify High-Income Areas:**
Look for the darker areas on the map, indicating higher income levels.

5. **Get Postal Codes and Income Info:**
Click on one of these darker areas to see the postal code and household income for that area.

![city data zip code](${CityDataZipcode})

6. **Search for Thrift Stores:**
Use Google to find thrift stores in the identified postal codes.

When visiting thrift and consignment stores in these high-income areas, also keep an eye out for garage and estate sales.

~~~~light,${colors.white}
#### Chapter 3 - How to Develop a Sourcing Strategy

A solid sourcing strategy is key to making informed decisions, minimizing risks, and ensuring your efforts lead to profitable outcomes. 

Here’s how to put together a strategy of your own:

~~~~light,${colors.white}
##### 3.1 - Focus on Financial Performance

- **Expected Selling Price:** Determine the potential selling price of an item before purchasing it. Take advantage of Poshwatch’s free product research tool — an easy and reliable way to find products that sell on Poshmark.

- **Cost of Goods (COGs):** Keep your purchasing costs as low as possible. The lower your costs, the higher your potential profits.

- **Expected Gross Profit:** Set a minimum profit threshold for each item. For instance, only add items to your inventory if you can make at least $25 in gross profit.

- **Expected Gross Profit Margin:** Aim for a minimum profit margin of 35%. This ensures you’re not just making sales but also making worthwhile profits.


~~~~light,${colors.white}
##### 3.2 - Emphasize Profits Over Margins

While high margins are appealing, they can be misleading. 

For instance, a 23% profit margin sounds impressive, but the actual profit might not be significant if the item was sold for a low value. With that in mind, focus on the actual dollar profit instead of only the percentage.

~~~~light,${colors.white}
##### 3.3 - Balance High-Value Sales and Low COGs

**High-value sales:** 

These items typically yield higher profits with each sale. However, they often have higher COGs, tying up more capital in inventory. Consider both the profit and the amount of capital invested.

**Low COGs:** 

These items have lower initial costs, but you need to sell them in higher volumes to match the profits from high-value sales items. Factor in the extra time and effort required to process more items.

~~~~light,${colors.white}
##### 3.4 - Time Management and Efficiency

**Track Your Time:** 

Time is your most precious resource; once it is gone, it’s gone. 

For this reason, you must track how long you spend sourcing, processing, and selling items. Compare the time invested to the potential profits to ensure you use your time efficiently.

**Time Costs:**

Different sourcing locations will provide different returns on your time investment, so it's best to track how long you spend at a location and what you get for your time spent in that location. Remember that each item requires more processing time for low COG items. 

This will reduce your overall profit if not managed well.

![city data zip code](${TimeManagement})

See here for more info on [Poshmark's top categories](https://poshwatch.io/top-categories-on-poshmark). This can help guide how you spend your time 

~~~~light,${colors.white}
##### 3.5 - Avoid Impulse Buys

It’s oh-so-tempting to fill your cart with anything that glitters with promise and potential.

However, we all know that promise and potential does not guarantee sales. Therefore, this can lead to a backlog of items that are not worth your time processing and selling. 

Be selective and stick to your strategy.


~~~~light,${colors.white}
##### 3.6 - Consider Poshmark Fees

After Poshmark’s 20% fee, ensure the remaining profit meets your minimum expected profit margin. 

For instance, if you need to make at least $20 after fees, you should be selling items for a price that accommodates this requirement. Our free calculator offers an instant and straightforward way to calculate your profits. 

Use a our [Fee calculator](https://poshwatch.io/poshmark-fee-calculator) to calculate your profits.

~~~~light,${colors.white}
#### Chapter 4 - Sourcing Strategies Based on Business Models

The success of your Poshmark business largely depends on your sourcing strategy, which should align with your specific business model. 

Here’s how to approach sourcing based on different business models:

~~~~light,${colors.white}
##### 4.1 - Low Cost of Goods (Low COGs)

If your strategy focuses on a low COGs model, the key is to find items at the lowest possible price. This often means scouring Goodwill outlets, bargain thrift stores, local garage sales, and estate sales.

Additionally, keeping an eye on free items listed on platforms like [Facebook Marketplace](https://www.facebook.com/marketplace/), [Craigslist](http://craigslist.org), or [Kijiji](https://www.kijiji.ca/) can be highly beneficial. 

However, this approach requires more time and effort, as you need to be swift and constantly monitor these sites to secure valuable items, which move way faster than you can blink.

![city data zip code](${LowCOGS})

~~~~light,${colors.white}
##### 4.2 - High-Value Sales

Aiming for high-value sales gives you more sourcing flexibility since you are not reliant on low prices to sustain your model. Thrift and consignment stores, liquidation sales, and even online consignment platforms like ThredUp and The RealReal can be excellent sources.

Focus on brands with high demand and set up alerts to stay ahead of competitors. Retail arbitrage, both in-store and online, can also yield profitable items. Nonetheless, ensure the higher-quality goods align with your pricing strategy to maintain your profit margins.

![city data zip code](${HighValueSales})

~~~~light,${colors.white}
##### 4.3 - Wholesale and Liquidation

For those willing to take on more risk, sourcing inventory through wholesale and liquidation pallets can offer substantial returns. This method involves purchasing large quantities of items at a reduced price, but it comes with the risk of receiving unsellable items.

Only pursue this option if you have the space, time, and capital to manage the volume and potential waste. Properly sorting and processing these items can lead to significant profits, but be prepared for the occasional loss.

~~~~light,${colors.white}
##### 4.4 - Out-of-Town Sourcing

Exploring thrift and consignment shops regions outside your own can uncover higher-quality items that may not be available locally. This is another perfect use case for [City-Data](https://www.city-data.com/) to identify affluent areas in regions where you are not familiar.

~~~~light,${colors.white}
#### Chapter 5 - Tools, Tips, and Techniques to Optimize Sourcing

We covered a lot, but we’re winding down.

This final chapter is about leveraging technology to maximize your success on Poshmark and simplify your overall sourcing process.

By leveraging various tools and techniques, you can streamline your efforts, make informed decisions, and ultimately increase your profits. Here are some effective tools and techniques to enhance your sourcing process:

~~~~light,${colors.white}
##### 5.1 - Poshwatch Tools and Resources

If you’re looking to simplify your reselling process, think of us as your one-stop shop with all the tools a resources you need.

We offer comprehensive assistance for resellers through a variety of tools.

- **Poshmark Product Research:** Want an easier way to do comp research? Use our [product research](https://poshwatch.io/top-selling-brands-on-poshmark) tool to help you find items that sell quickly on poshmark.

- **Poshmark Research Charts:** Want to know how brands, categories and styles are selling the fastest and at high values? We monitor and update sales data from Poshmark's [top brands](https://poshwatch.io/top-selling-brands-on-poshmark), [sellers](https://poshwatch.io/top-sellers-on-poshmark), and [categories](https://poshwatch.io/top-categories-on-poshmark) daily.

- **Poshmark Assistant:** Tired of sharing your items manually? Our [Poshmark assistant](https://poshwatch.io/poshmark-assistant) will automatically share your closet and help you make more sales.

- **Poshmark Listing Analyzer:** Need help creating listings that rank higher in poshmark search results and sell faster? use our [Poshmark listing analyzer](https://poshwatch.io/listing-analyzer-poshmark) to gain insights that help you optimize your listings that will improve traffic and get your items sold faster.

- **Guides:** Our guides are simple and fun to read, providing you with practical advice and strategies for successful reselling. Find our guides [here](https://poshwatch.io/guides).

~~~~light,${colors.white}
##### 5.2 - Market Research Tools

- **Poshmark Search Filters and Trends:** Utilize Poshmark’s built-in search filters to identify current trends and popular items. Pay attention to their [Featured Trends](https://poshmark.com/trends) page to see what’s trending and adjust your sourcing strategy accordingly. Use Poshmark’s “Filter” options to find top-selling categories and brands.

- **Google Trends:** Use Google Trends to analyze search interest over time. By entering keywords related to the products you’re considering, you can see if they are gaining or losing popularity.

    For instance, if searches for “vintage 90s sneakers” are increasing, it might be a good time to look for these items.

~~~~light,${colors.white}
##### 5.3 - Sourcing Techniques

- **Scouting local thrift stores and outlets:** Aim to visit sourcing spots during their restocking periods—often Saturday mornings. Don't just rely on luck; speak with store employees to uncover their restock schedules. When in store always prioritize new inventory first, shifting your focus to the racks being rolled out as soon as they appear. 

    Familiarize yourself with sales schedules and tags to identify new and on-sale items quickly especially in places with lots of competition. Take note of when new racks and bins are rolled out, so you know what days and times to visit.

- **Strategic Networking:** Join local and online reseller communities. Facebook groups, Reddit threads, and reseller forums are great places to share tips, find sourcing opportunities, and learn from others’ experiences. Engaging with these communities can provide leads on where to find high-quality inventory.

- **Liquidation Pallets:** For those who are willing to take on more risk, liquidation pallets can offer high-reward opportunities. Websites like JTC Auctions allow you to purchase large quantities of goods at a discount. This approach requires careful sorting and evaluation but can lead to profitable finds if managed well.


~~~~light,${colors.white}
##### 5.4 - Time Management Techniques

- **Time Tracking:** Track the time you spend on sourcing activities, including travel, browsing, and processing items. Evaluate the return on your investment by comparing the potential profits from different locations. 

    For instance, if you spend one hour at a store and find $1500 in potential profits, it's a far better return than two hours at another spot leaving with only $275 in potential profits. 
    
    This practice helps you identify the most efficient sourcing methods, ensuring you maximize your time and profits.

- **Setting Time Limits:** When visiting stores or searching online, set specific time limits to avoid spending too much time on low-yield activities. For example, allocate one hour for a thrift store visit and stick to it. 

    This helps you stay focused and makes your sourcing efforts more effective.

- **Batch Processing:** Group similar tasks together to maximize efficiency. For instance, spend one day sourcing and another day taking photos then the last writing up the listings on Poshmark. This approach streamlines your workflow and reduces the time spent switching between different types of tasks. 

    For more tips on batching and maintaining a consistent listing routine, see our article: [How to List Consistently on Poshmark](https://poshwatch.io/guides/how-to-list-consistently-on-poshmark).

~~~~light,${colors.white}
##### 5.5 - Sourcing Best Practices

- **Evaluating Comps:** This helps us determine if something is worth adding to our inventory. Do not make the mistake of skipping this step in the name of saving time. Trust me, you will pay for this at a later date. There is no point in spending time processing an item that has no market demand, or is saturated and therefore has no value. 

    Do comp research on EVERYTHING you pick up.
    
    This is the best way to lower your risk of bad purchases. Establishing this discipline is what separates a novice from a professional.

- **Understanding Profit Margins:** Calculate the potential profit margins for each item by considering the cost of goods, selling price, and Poshmark’s fees. Make sure that each item meets your minimum profit criteria before making a purchase.

- **Inspecting Items Thoroughly:** Always check items for defects, stains, or damage before purchasing. A thorough inspection helps you to avoid buying items that will be difficult to sell or require additional work to fix. 

    Also, it’s not just used items in thrift stores that you need to check. Oftentimes, clothing will end up at outlet malls because of defects that make it no longer sellable at full price. For a more thorough guide on assessing quality and key areas to inspect, see our article: [Top Seller's Guide To Identifying High Quality Clothing](https://poshwatch.io/guides/reseller-guide-to-identifying-high-quality-clothing)


~~~~light,${colors.white}
#### Conclusion

And just like that, you have all the information and resources neatly packaged for you in this blog. To help you source an inventory that will make you impressive profits.

Now you can go out, have fun, and source with confidence. Happy sourcing!`;

export default howToSource;