import React, { useEffect, useState, useMemo } from 'react';
import { lightTheme } from '../customTheme';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import Panel from '../components/Panel';
import { Container, Typography, Grid, Card, CardContent, Link } from '@mui/material';
import { colors } from '../customTheme';
import ToggleButtons from '../components/ToggleButtons';
import Skeleton from '@mui/material/Skeleton';
import { useParams } from 'react-router-dom';
import { getStats } from '../api/client';
import { formatTotalPrice, formatNumberCompact, formatNumberStandard, formatName } from '../utils/helpers';
import SalesChart from '../components/SalesChart';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import NewsletterPanel from '../components/NewsletterPanel';

const StatCard = ({ stat }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Card variant="outlined">
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {stat.title}
        </Typography>
        <Typography variant="body" style={{ fontWeight: "500" }}>
          {stat.value}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

const StatsGridView = ({ stats }) => (
  <Grid container spacing={2} paddingTop={2}>
    {stats.map((stat, index) => (
      <StatCard key={index} stat={stat} />
    ))}
  </Grid>
);

export const Details = () => {
  const [results, setResults] = useState(null);
  const { brand, username } = useParams();

  const [loading, setLoading] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState("sales");
  const [selectedRange, setSelectedRange] = useState("ALL");

  const onSeriesToggle = () => {
    setSelectedSeries(selectedSeries === "sales" ? "count" : "sales");
  };

  const allChartData = useMemo(() => {
    if (!results?.chartData || results?.chartData.length === 0) {
      return [];
    }

    const chartData = results.chartData;

    // Create a map to store data by date
    const dateMap = new Map();
    chartData.forEach((item) => {
      const dateStr = new Date(item.date).toISOString().split('T')[0];
      dateMap.set(dateStr, { ...item });
      delete dateMap.get(dateStr).date;
    });

    // Get the current date
    const currentDate = new Date();

    // Determine the start date based on the selectedRange
    let startDate = new Date();

    if (selectedRange === "7D") {
      startDate.setDate(currentDate.getDate() - 7);
    } else if (selectedRange === "1M") {
      startDate.setMonth(currentDate.getMonth() - 1);
    } else if (selectedRange === "3M") {
      startDate.setMonth(currentDate.getMonth() - 3);
    } else if (selectedRange === "ALL") {
      // For ALL, set the start date to the earliest date in the data
      startDate = new Date(
        Math.min(...chartData.map((item) => new Date(item.date)))
      );
    }

    // Create an array with all dates within the selected range
    const allDates = [];
    let currentDateIterator = new Date(startDate);

    while (currentDateIterator <= currentDate) {
      const dateStr = currentDateIterator.toISOString().split('T')[0];
      const matchingData = dateMap.get(dateStr);

      if (!matchingData) {
        // If date is missing in the dateMap, add it with sales and count set to zero
        allDates.push({
          date: new Date(dateStr),
          sales: 0,
          count: 0,
          likes: 0,
          shares: 0,
          offers: 0,
          comments: 0,
          totalDaysOnMarket: 0,
          totalItemsWithValidDates: 0,
        });
      } else {
        allDates.push({
          ...matchingData,
          date: new Date(dateStr),
        });
      }

      currentDateIterator.setDate(currentDateIterator.getDate() + 1);
    }

    return allDates;
  }, [results, selectedRange]);

  const stats = useMemo(() => {
    const totalSales = allChartData.reduce((accumulator, entry) => accumulator + entry.sales, 0);
    const salesPerDay = allChartData.length > 0 ? totalSales / allChartData.length : 0;
    const totalItemsSold = allChartData.reduce((accumulator, entry) => accumulator + entry.count, 0);
    const totalItemsPerDay = allChartData.length > 0 ? totalItemsSold / allChartData.length : 0;
    const averageSalePrice = totalItemsSold > 0 ? totalSales / totalItemsSold : 0;

    const totalDaysOnMarket = allChartData.reduce((accumulator, entry) => {
      const daysOnMarket = isNaN(entry.totalDaysOnMarket) ? 0 : entry.totalDaysOnMarket;
      return accumulator + daysOnMarket;
    }, 0);

    const totalItemsWithValidDates = allChartData.reduce((accumulator, entry) => {
      const itemsWithValidDates = isNaN(entry.totalItemsWithValidDates) ? 0 : entry.totalItemsWithValidDates;
      return accumulator + itemsWithValidDates;
    }, 0);

    const avgTimeToSell = totalItemsWithValidDates > 0 ? totalDaysOnMarket / totalItemsWithValidDates : 0;

    const likes = allChartData.reduce((accumulator, entry) => accumulator + entry.likes, 0);
    const comments = allChartData.reduce((accumulator, entry) => accumulator + entry.comments, 0);
    const shares = allChartData.reduce((accumulator, entry) => accumulator + entry.shares, 0);
    const offers = allChartData.reduce((accumulator, entry) => accumulator + entry.offers, 0);

    const likesPerItem = totalItemsSold > 0 ? likes / totalItemsSold : 0;
    const commentsPerItem = totalItemsSold > 0 ? comments / totalItemsSold : 0;
    const sharesPerItem = totalItemsSold > 0 ? shares / totalItemsSold : 0;
    const offersPerItem = totalItemsSold > 0 ? offers / totalItemsSold : 0;

    return {
      totalSales,
      salesPerDay,
      totalItemsSold,
      totalItemsPerDay,
      averageSalePrice,
      avgTimeToSell,
      likesPerItem,
      commentsPerItem,
      sharesPerItem,
      offersPerItem,
    };
  }, [allChartData]);

  const statsMap = useMemo(() => {
    return [
      { title: 'TOTAL SALES (USD)', value: formatTotalPrice(stats.totalSales) },
      { title: 'AVERAGE SALES PER DAY (USD)', value: formatTotalPrice(stats.salesPerDay) },
      { title: 'AVERAGE SALE PRICE (USD)', value: formatTotalPrice(stats.averageSalePrice) },

      { title: 'TOTAL ITEMS SOLD', value: formatNumberStandard(stats.totalItemsSold, 2) },
      { title: 'AVERAGE ITEMS SOLD PER DAY', value: formatNumberStandard(stats.totalItemsPerDay, 2) },

      { title: 'AVERAGE TIME ON MARKET', value: `${formatNumberCompact(stats.avgTimeToSell)} Days` },

      // { title: 'LIKES PER ITEM', value: (likesPerItem).toFixed(2) },
      // { title: 'COMMENTS PER ITEM', value: (commentsPerItem).toFixed(2) },
      { title: 'SHARES PER ITEM', value: (stats.sharesPerItem).toFixed(2) },
      { title: 'OFFERS PER ITEM', value: (stats.offersPerItem).toFixed(2) },
    ];
  }, [stats]);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        let result = null;

        if (username) {
          result = await getStats({ username });
        }
        else if (brand) {
          result = await getStats({ brand });
        }

        setResults(result);

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [brand, username]);

  const SkeletonRows = () => {
    const skeletonRows = Array.from({ length: 5 }, (_, index) => (
      <Skeleton key={index} height={48} animation="pulse" />
    ));

    return <Container>{skeletonRows}</Container>;
  };

  const getHref = () => {
    if (username) {
      return `https://poshmark.com/closet/${username}?availability=sold_out`;
    }
    else if (brand) {
      return `https://poshmark.com/brand/${brand}?availability=sold_out`;
    }
  }

  const onRangeChange = (_, value) => {
    setSelectedRange(value);
  };

  const rangeButtons = [
    { label: "7D", value: "7D" },
    { label: "1M", value: "1M" },
    { label: "3M", value: "3M" },
    { label: "ALL", value: "ALL" },
  ];

  const renderLineChart = () => {
    return (
      <Card variant="outlined">
        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ToggleButtons
              value={selectedSeries}
              onChange={onSeriesToggle}
              width={110}
              options={[
                { value: "sales", label: 'Sales' },
                { value: "count", label: 'Items Sold' },
              ]} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <ToggleButtons
              value={selectedRange}
              onChange={onRangeChange}
              options={rangeButtons}
            />
          </div>
          <SalesChart allChartData={allChartData} selectedSeries={selectedSeries} />
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Helmet bodyAttributes={{ style: `background-color : ${colors.alabaster}` }} >
        <title>{`${username}'s Closet`}</title>
        <meta name="description" content={`The latest sales data for ${username}'s closet. Total sales, average sales per day, total items sold and more!`} />
        <meta property="og:title" content={`Poshmark Top Seller: ${username}`} />
        <meta property="og:description" content="Learn from the top sellers so you can boost your Poshmark sales" />
        <meta property="og:url" content={`https://poshwatch.io/seller/${username}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ResponsiveAppBar />
      <Panel theme={lightTheme}>
        <Container maxWidth='md' style={{ paddingBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Link style={{ textDecoration: 'none' }} href={getHref()} target={'_blank'} paddingBottom={2}>
            <Typography variant='h4' align='center' style={{ fontFamily: "Libre Baskerville" }}>
              {`Poshmark Top Seller: @${username}` ?? formatName(brand)}
            </Typography>
          </Link>
          <Link style={{ textDecoration: 'none' }} href={getHref()} target={'_blank'} paddingBottom={4}>
            {username ? 'Visit closet' : 'Visit brand page'}
          </Link>
        </Container>
        <Container maxWidth='lg'>
          <div style={{ width: '100%' }}>
            {!loading && allChartData && renderLineChart()}
            {loading && <SkeletonRows />}
          </div>
          <StatsGridView stats={statsMap} />
        </Container>
      </Panel>
      <NewsletterPanel />
      <Footer />
    </div>
  );
};
