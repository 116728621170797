import React from 'react';
import { formatTotalPrice, formatNumberCompact, formatName } from '../utils/helpers';
import { TopPage, NameCell, CustomChip } from './TopPage';
import PATHS from '../utils/paths';

const showAvatar = true;

const columns = [
  { field: 'id', headerName: 'Rank', width: 80 },
  {
    field: 'name',
    headerName: 'Seller',
    flex: 2,
    renderCell: (params) => (
      <NameCell name={params.value} href={`/seller/${params.value}`} />
    ),
  },
  {
    field: 'totalPrice',
    headerName: 'Sales',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'soldItemsCount',
    headerName: 'Items Sold',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatNumberCompact(value),
  },
  {
    field: 'averageSalePrice',
    headerName: 'Average Price',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'brands',
    headerName: 'Top Brands',
    sortable: false,
    flex: 4,
    renderCell: (params) => (
      <div>
        {
          params.value
            ?.slice(0, 3)
            .map((brand, index) => (
              <CustomChip
                key={index}
                showAvatar={showAvatar}
                name={formatName(brand.name)}
                count={brand.count}
                index={index}
              />
            ))
        }
      </div>
    ),
  },
];

const TopSellers = () => {
  return (
    <TopPage
      type={"creators"}
      columns={columns}
      title={"The Top 100 Sellers on Poshmark"}
      description={"Uncover the highest ranking sellers on Poshmark so you can learn from the best and increase your sales."}
      href={PATHS.TOP_SELLERS}
      toggleOptions={[
        { value: "1d", label: '1D' },
        { value: "3d", label: '3D' },
        { value: "7d", label: '7D' },
      ]}
      subtitle={
        <div>
          <p>
            Learn from high ranking sellers to improve your listings and make more Poshmark sales.
          </p>
        </div>
      }
    />
  );
};

export default TopSellers;
