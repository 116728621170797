import React, { useMemo } from 'react';
import { formatTotalPrice, formatNumberCompact, formatName } from '../utils/helpers';
import { TopPage, NameCell, CustomChip } from './TopPage';
import PATHS from '../utils/paths';

const showAvatar = false;

const columns = [
  { field: 'id', headerName: 'Rank', width: 80 },
  {
    field: 'name',
    headerName: 'Category',
    flex: 2,
    renderCell: (params) => (
      <NameCell name={formatName(params.value)} href={`/category/${params.value}`} />
    ),
  },
  {
    field: 'soldItemsCount',
    headerName: 'Items Sold',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatNumberCompact(value),
  },
  {
    field: 'totalPrice',
    headerName: 'Sales',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'averageSalePrice',
    headerName: 'Average Price',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'brands',
    headerName: 'Top Brands',
    sortable: false,
    flex: 4,
    renderCell: (params) => (
      <div>
        {
          params.value
            ?.slice(0, 3)
            .map((brand, index) => (
              <CustomChip
                key={index}
                showAvatar={showAvatar}
                name={formatName(brand.name)}
                count={brand.count}
                index={index}
              />
            ))
        }
      </div>
    ),
  },
];

const TopCategories = () => {

  const LastUpdatedDate = useMemo(() => {
    // Calculate the date 3 days from now
    const today = new Date();
    const updatedDate = new Date(today);
    updatedDate.setDate(updatedDate.getDate() - 1);
    const formattedDate = updatedDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });

    return formattedDate;
  }, []);

  return (
    <TopPage
      type={"categories"}
      columns={columns}
      title={"The Top 100 Categories on Poshmark"}
      description={"Curious about what's in demand? Use this resource to help you source top-selling items for Poshmark and boost your sales."}
      href={PATHS.TOP_CATEGORIES}
      toggleOptions={[
        { value: "1m", label: '1M' },
      ]}
      toggleDefault="1m"
      subtitle={
        < div >
          <p>
            Use this resource to help you source top-selling items for Poshmark and boost your sales.
          </p>
          <p>
            Last updated: {LastUpdatedDate}
          </p >
        </div >
      }
    />
  );
};

export default TopCategories;
