import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const slatePurple = '#7b61ff';
const alabaster = '#fafafa';
const mineShaft = '#333333';
const green = '#15ab6c';
const white = '#fff';
const elfGreen = '#15925f';
const doveGrey = '#717171';
const spaceGrey = '#1d1d20';
const lightGrey = '#f5f5f5';
const revolverPurple = '#261C3A';
const wafer = '#d1bfae';
const gold = '#FFE4B5';
const woodsmoke = '#0e0712';
const wildSand = '#f7f7f7';
const seaShell = '#f1f2ef';

const purpleToIndigoGradient = 'linear-gradient(180deg, #9746ff, #684dff)';
const spaceGreyGradient = `linear-gradient(180deg, ${mineShaft}, ${spaceGrey})`;

const colors = {
  seaShell,
  wildSand,
  woodsmoke,
  gold,
  green,
  elfGreen,
  slatePurple,
  alabaster,
  doveGrey,
  mineShaft,
  purpleToIndigoGradient,
  white,
  spaceGrey,
  lightGrey,
  spaceGreyGradient,
  revolverPurple,
  wafer,
}

const typography = {
  fontFamily: [
    'Inter',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
  ].join(','),
  h1: {
    fontSize: '4rem',
    fontWeight: '700',
  },
  h2: {
    fontSize: '3.2rem',
    fontWeight: '700',
  },
  h3: {
    fontSize: '2.8rem',
    fontWeight: '700',
  },
  h4: {
    fontSize: '2.2rem',
    fontWeight: '700',
  },
  h5: {
  },
  body1: {
    fontSize: '1.1 rem'
  }
}

const link = {
  styleOverrides: {
    root: {
      color: slatePurple,
    },
  },
}

let lightTheme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: alabaster,
    },
    text: {
      primary: mineShaft
    },
    primary: {
      // This controls lots of things like DataGrid cell selection color labels on TextFields, etc. It needs to contrast with the main background color
      main: mineShaft,
    },
  },
  typography: {
    ...typography,
    allVariants: {
      color: mineShaft,
    },
  },
  components: {
    MuiLink: link,
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

let darkTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: mineShaft,
    },
    text: {
      primary: alabaster,
      secondary: '#DDD',
    },
    primary: {
      main: mineShaft,
    },
  },
  typography: {
    ...typography,
    allVariants: {
      color: alabaster,
    },
  },
  components: {
    MuiLink: link,
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

lightTheme = responsiveFontSizes(lightTheme);
darkTheme = responsiveFontSizes(darkTheme);

export { colors, lightTheme, darkTheme };
