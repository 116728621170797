import React, { createContext, useContext, useEffect, useState } from "react";
import { MyFirebase } from "../api/my_firebase.ts";
import { subscriptions } from '../api/client';
import { Analytics } from 'monolib';

// Create a context for logged-in state
const AuthContext = createContext();

// Custom hook to access the AuthContext
export const useAuth = () => useContext(AuthContext);

// Auth provider component
export const AuthProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(null);
  const [isPremium, setIsPremium] = useState(null);
  const [email, setEmail] = useState(null);

  const app = MyFirebase.getInstance().getApp();

  useEffect(() => {
    // Add Firebase event listener to track auth state changes
    const unsubscribe = MyFirebase.getInstance().getAuth().onAuthStateChanged(async (user) => {
      setIsSignedIn(!!user); // Update isSignedIn state based on auth state

      const premium = user ? await subscriptions(user.uid) : false;
      setIsPremium(premium);

      if (user) {
        Analytics.instance.identify(user.uid, {
          email: user.email,
          plan: premium ? 'premium' : 'free',
        })
      }
      else {
        Analytics.instance.reset()
      }

      setEmail(user?.email ?? null);
    });
    // Unsubscribe from Firebase listener when component unmounts
    return () => unsubscribe();
  }, [app]);

  return (
    <AuthContext.Provider value={{ isSignedIn, isPremium, email }}>
      {children}
    </AuthContext.Provider>
  );
};
