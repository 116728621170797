import React from 'react';
import { Box, Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { colors, darkTheme } from '../customTheme';
import TopBrandsPremium from '../images/premium/top_brands_premium.png';
import PrimaryButton from './PrimaryButton';
import { useNavigate } from 'react-router-dom';
import PATHS from '../utils/paths';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const PremiumBanner = () => {
  const navigate = useNavigate();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={darkTheme}>
      <Card variant="outlined"
        style={{
          backgroundColor: colors.slatePurple,
          position: 'relative',
          overflow: 'hidden',
          paddingRight: '10px',
          paddingLeft: '10px',
        }}>
        <CardContent>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <AutoAwesomeIcon sx={{ marginRight: "2px" }} />
            <Typography variant="h4" style={{ marginLeft: '8px' }}>
              Premium
            </Typography>
          </Box>
          <Typography variant="body1" paddingTop={1} style={{ width: isSm ? "100%" : "50%" }}>
            See the entire top 200 list for brands and sellers. Explore detailed seller history & advanced stats.
          </Typography>
          <PrimaryButton
            variant="contained"
            color="primary"
            style={{
              backgroundColor: colors.green,
              color: 'white',
              marginTop: "20px",
              paddingLeft: "40px",
              paddingRight: "40px",
              width: isSm ? "100%" : "auto",
            }}
            onClick={() => {
              navigate(PATHS.PREMIUM);
            }}
          >
            Try Now
          </PrimaryButton>
          {!isSm && (
            <Box style={{ position: 'absolute', top: "20%", transform: "translateX(50%)" }}>
              <img alt="Top brands Premium" src={TopBrandsPremium} style={{ width: "80%", height: "auto" }} />
            </Box>
          )}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default PremiumBanner;
