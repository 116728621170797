import SaltBae from './images/salt-bae.png';
import Arthur1 from './images/arthur/arthur-ears-1.png';
import Arthur2 from './images/arthur/arthur-ears-2.png';
import Arthur3 from './images/arthur/arthur-ears-3.png';

export interface Tweet {
  name: string;
  username: string;
  message: string;
  images?: string[];
}

type ChartDataPoint = {
  label: string;
  count: number;
};

export type ChartData = {
  title: string;
  subtitle: string;
  data: ChartDataPoint[],
}

export const tweets: Tweet[] = [
  {
    "name": "Poshwatch",
    "username": "poshwatch.io",
    "message": "Poshmark customer support listening to their users",
    "images": [Arthur1, Arthur2, Arthur3],
  },
  {
    "name": "Emma",
    "username": "CoffeeAndClothes",
    "message": "When I die spread my ashes in the Goodwill bins 💀",
    "images": SaltBae,
  },
  {
    "name": "Jake",
    "username": "AdventureSeeker",
    "message": "That moment when you find a designer item at the thrift store for $5... and it's actually authentic!"
  },
  {
    "name": "Sarah",
    "username": "BookwormBaker",
    "message": "Dear potential buyers: Asking me to model a size XXS when I'm clearly not that size is... interesting."
  },
  {
    "name": "Mike",
    "username": "TechEnthusiast",
    "message": "Pro tip: If you're going to lowball, at least have the decency to use the offer button."
  },
  {
    "name": "Lily",
    "username": "GreenThumbGal",
    "message": "Just had a buyer leave 5 stars and the comment 'Perfect!' Then they opened a case. Make it make sense. 🤷‍♀️"
  },
  {
    "name": "Alex",
    "username": "FitnessFanatic",
    "message": "Spent my day off 'relaxing' by reorganizing my entire inventory. Tell me you're a reseller without telling me you're a reseller."
  },
  {
    "name": "Olivia",
    "username": "WanderlustDreamer",
    "message": "When you finally clear out your death pile and feel like you've conquered the world. Small victories!"
  },
  {
    "name": "Ethan",
    "username": "MusicMaestro",
    "message": "Note to self: Stop buying things 'for your closet' that are actually for your personal closet."
  },
  {
    "name": "Zoe",
    "username": "ArtisticSoul",
    "message": "That feeling when you get an offer notification... and it's actually reasonable! 😱"
  },
  {
    "name": "Noah",
    "username": "FoodieFanatic",
    "message": "Sometimes I wonder if my packages arrive safely, then I remember I put enough tape on them to survive the apocalypse."
  }
];

export const pies: ChartData[] = [
  {
    title: "Leggings Dominate Poshmark's Pants Category",
    subtitle: "Most popular pant styles on Poshmark",
    data: [
      { label: "Leggings", count: 7000 },
      { label: "Jumpsuits & Rompers", count: 6000 },
      { label: "Track Pants & Joggers", count: 4000 },
      { label: "Wide Leg", count: 3000 },
      { label: "Ankle & Cropped", count: 2000 },
      { label: "Straight Leg", count: 2000 },
      { label: "Trousers", count: 1000 },
      { label: "Capris", count: 1000 },
      { label: "Boot Cut & Flare", count: 823 },
      { label: "Skinny", count: 445 },
    ],
  },
  {
    title: "Athletic Shorts Lead Summer Trends",
    subtitle: "Most popular shorts by sales on Poshmark",
    data: [
      { label: "Athletic Shorts", count: 9000 },
      { label: "Jean Shorts", count: 6000 },
      { label: "Athletic", count: 3000 },
      { label: "Skorts", count: 2000 },
      { label: "High Waist", count: 1000 },
      { label: "Flat Front", count: 1000 },
      { label: "Bike Shorts", count: 1000 },
      { label: "Bermudas", count: 1000 },
      { label: "Cargo", count: 770 },
      { label: "Cargos", count: 324 },
    ],
  },
  {
    title: "Crossbody Bags Top the Charts",
    subtitle: "Most popular bag styles by sales on Poshmark",
    data: [
      { label: "Crossbody Bags", count: 9000 },
      { label: "Totes", count: 6000 },
      { label: "Shoulder Bags", count: 6000 },
      { label: "Wallets", count: 5000 },
      { label: "Backpacks", count: 4000 },
      { label: "Clutches & Wristlets", count: 2000 },
      { label: "Cosmetic Bags & Cases", count: 2000 },
      { label: "Satchels", count: 2000 },
      { label: "Mini Bags", count: 1000 },
      { label: "Travel Bags", count: 1000 },
    ],
  },
  {
    title: "Midi Dresses Lead the Fashion Pack",
    subtitle: "Most popular dress styles by on Poshmark",
    data: [
      { label: "Midi", count: 14000 },
      { label: "Mini", count: 12000 },
      { label: "Maxi", count: 10000 },
      { label: "Casual", count: 1000 },
      { label: "Long Sleeve", count: 1000 },
      { label: "Wedding", count: 704 },
      { label: "Strapless", count: 574 },
      { label: "Asymmetrical", count: 409 },
      { label: "High Low", count: 402 },
      { label: "Prom", count: 382 },
    ],
  },
  {
    title: "Summer Tops Trend on Poshmark",
    subtitle: "Most popular tops on Poshmar for the past month",
    data: [
      { label: "Blouses", count: 14000 },
      { label: "Tank Tops", count: 11000 },
      { label: "Tees Short Sleeve", count: 10000 },
      { label: "Sweatshirts & Hoodies", count: 5000 },
      { label: "Button Down Shirts", count: 4000 },
      { label: "Crop Tops", count: 4000 },
      { label: "Tees Long Sleeve", count: 3000 },
      { label: "Tunics", count: 2000 },
      { label: "Bodysuits", count: 2000 },
      { label: "Camisoles", count: 961 },
    ],
  },
  {
    title: "Sandals Lead Summer Footwear Trends",
    subtitle: "Top-trending footwear styles on Poshmark this month",
    data: [
      { label: "Sandals", count: 18000 },
      { label: "Sneakers", count: 15000 },
      { label: "Athletic Shoes", count: 10000 },
      { label: "Heels", count: 6000 },
      { label: "Flats & Loafers", count: 6000 },
      { label: "Ankle Boots & Booties", count: 3000 },
      { label: "Mules & Clogs", count: 3000 },
      // { label: "Sandals & Flip Flops", count: 3000 },
      // { label: "Boots", count: 2000 },
      // { label: "Heeled Boots", count: 2000 },
    ],
  },
  {
  title: "Flare Leg Jeans Are Making a Comeback",
  subtitle: "Poshmark's best selling jean styles in the last 30 days",
  data: [
    { label: "Flare & Wide Leg", count: 3172 },
    { label: "Skinny", count: 2058 },
    { label: "Straight Leg", count: 1987 },
    { label: "Boot Cut", count: 2104 },
    { label: "Ankle & Cropped", count: 1946 },
    { label: "High Rise", count: 1023 },
    { label: "Straight", count: 971 },
    { label: "Boyfriend", count: 450 },
    // { label: "Bootcut", count: 378 },
    // { label: "Relaxed", count: 347 },
  ],
}];
