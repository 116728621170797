import React from 'react';
import { formatTotalPrice, formatNumberCompact, formatName } from '../utils/helpers';
import { TopPage, NameCell, CustomChip } from './TopPage';
import PATHS from '../utils/paths';

// Deprecated use formatters.ts instead
const formatPath = (name) => {
  return name
    .replace(/_/g, "-") // Replace underscores with hyphens
    .replace(/&/g, "and") // Replace & with 'and'
    .replace(/,/g, "") // Replace commas
    .replace(/\./g, "") // Replace dots with underscores
    .replace(/'/g, "") // Replace single quotes
    .replace(/_+/g, "_") // Replace any number of underscores with a single underscore
    .toLowerCase(); // Convert everything to lowercase
};

const showAvatar = true;

const columns = [
  { field: 'id', headerName: 'Rank', width: 80 },
  {
    field: 'name',
    headerName: 'Brand',
    flex: 2,
    renderCell: (params) => (
      <NameCell name={formatName(params.value)} href={`/brand/${formatPath(params.value)}`} />
    ),
  },
  {
    field: 'totalPrice',
    headerName: 'Sales',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'soldItemsCount',
    headerName: 'Items Sold',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatNumberCompact(value),
  },
  {
    field: 'averageSalePrice',
    headerName: 'Average Price',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'categories',
    headerName: 'Top Categories',
    sortable: false,
    flex: 4,
    renderCell: (params) => (
      <div>
        {
          params.value
            .filter((category) => params.value.length <= 3 || category.name !== 'Other')
            .slice(0, 3)
            .map((category, index) => (
              <CustomChip
                key={index}
                showAvatar={showAvatar}
                name={category.name}
                count={category.count}
                index={index}
              />
            ))
        }
      </div>
    ),
  },
];

const TopBrands = () => {
  return (
    <TopPage
      type={"brands"}
      columns={columns}
      title={"The 100 Best Selling Brands on Poshmark"}
      description={"Curious what's trending? Use this resource to help you source top-selling items for Poshmark and boost your sales."}
      href={PATHS.TOP_BRANDS}
      toggleOptions={[
        { value: "1d", label: '1D' },
        { value: "3d", label: '3D' },
        { value: "7d", label: '7D' },
      ]}
      subtitle={
        < div >
          <p>
            Insights to help you source high demand items for your closet and boost your sales.
          </p>
        </div >
      }
    />
  );
};

export default TopBrands;
