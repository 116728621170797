import React from 'react';
import {
  Card,
  CardContent,
  styled,
  Typography,
  Avatar,
} from '@mui/material';
import { colors } from '../../customTheme';
import PostTemplate from './PostTemplate.tsx';
import { Tweet } from './data';
import { useData } from './DataProvider.tsx';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '85%',
  borderRadius: theme.spacing(2),
  '& .MuiCardContent-root': {
    padding: theme.spacing(4),
  },
  border: `1.5px solid #939598`,
  boxShadow: `-10px 10px 0px 0px ${colors.wafer}`,
  background: 'white',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ImageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StyledImage = styled('img')({
  borderRadius: '4px',
  objectFit: 'cover',
});

export const TwitterCard: React.FC = () => {
  const { currentData: tweet, data } = useData<Tweet>();

  if (!tweet) return null;

  const renderImages = () => {
    if (!tweet.images || tweet.images.length === 0) return null;

    if (tweet.images.length === 1) {
      return (
        <StyledImage
          src={tweet.images[0]}
          alt=""
          style={{ width: '100%', height: 'auto' }}
        />
      );
    }

    if (tweet.images.length === 2) {
      return (
        <ImageContainer>
          {tweet.images.map((image, index) => (
            <StyledImage
              key={index}
              src={image}
              alt=""
              style={{ width: '50%', height: '400px' }}
            />
          ))}
        </ImageContainer>
      );
    }

    if (tweet.images.length === 3 || tweet.images.length === 4) {
      return (
        <ImageContainer>
          <StyledImage
            src={tweet.images[0]}
            alt=""
            style={{ width: '50%', height: '400px' }}
          />
          <div style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {tweet.images.slice(1, 3).map((image, index) => (
              <StyledImage
                key={index}
                src={image}
                alt=""
                style={{ width: '100%', height: '196px' }}
              />
            ))}
            {tweet.images[3] && (
              <StyledImage
                src={tweet.images[3]}
                alt=""
                style={{ width: '100%', height: '196px' }}
              />
            )}
          </div>
        </ImageContainer>
      );
    }
  };

  return (
    <PostTemplate theme="dark" numScreenshots={data.length}>
      <StyledCard>
        <CardContent>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: `24px` }}>
            <Avatar
              alt={tweet.name.toLocaleUpperCase()}
              style={{
                fontSize: `3rem`,
                fontWeight: 'bold',
                marginRight: `24px`,
                width: `80px`,
                height: `80px`
              }}
            />
            <div>
              <Typography
                fontFamily="Libre Baskerville"
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  fontSize: `2rem`
                }}
              >
                {tweet.name}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: `2rem` }}
              >
                @{tweet.username}
              </Typography>
            </div>
          </div>
          <Typography
            variant="body1"
            style={{
              marginBottom: `4px`,
              fontSize: `2rem`
            }}
          >
            {tweet.message}
          </Typography>
          {renderImages()}
        </CardContent>
      </StyledCard>
    </PostTemplate>
  );
};