import React from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { colors } from '../customTheme';
import PATHS from '../utils/paths';
import { useNavigate } from 'react-router-dom';

const BlurContainer = ({ title, children, blur }) => {
  const navigate = useNavigate();

  const handleTryPremium = async () => {
    navigate(PATHS.PREMIUM);
  }

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        position: 'relative',
        overflow: 'hidden',
      }}
      style={{ minHeight: '200px' }}
    >
      {blur && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            zIndex: 1,
          }}
        >
          <Typography variant="body2">
            Want more insights?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 1, backgroundColor: colors.green, boxShadow: 'none', fontWeight: 'bold' }}
            onClick={handleTryPremium}
          >
            Try Premium
          </Button>
        </Box>
      )}
      <Typography variant="body2" align="center" sx={{ pt: 2, pb: 1, px: 2 }}>
        {title}
      </Typography>
      <Box
        sx={{
          filter: blur ? 'blur(5px)' : 'none',
          zIndex: 0,
          userSelect: blur ? 'none' : 'auto',
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default BlurContainer;