/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { lightTheme } from '../../customTheme';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar';
import Panel from '../../components/Panel';
import { Container, Typography, Alert, Box, Card, CardContent } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { colors } from '../../customTheme';
import Footer from '../../components/Footer';
import ShareNowPlaceholder from '../../images/assistant/share_now_placeholder.png';
import useExtensionVersion from '../../hooks/useExtensionVersion.ts';

const PlaceholderCard = () => {
  return (
    <Box paddingBottom={2}>
      <Card variant="outlined">
        <CardContent>
          <img
            src={ShareNowPlaceholder}
            alt="Share now placeholder"
            style={{
              maxWidth: '100%',
              height: 'auto',
              filter: 'grayscale(100%)',
              pointerEvents: 'none'
            }} />
        </CardContent>
      </Card>
    </Box>
  );
}

const Assistant = ({ title, subtitle, description, href }) => {
  const { showExtensionInstallWarning, installExtensionMessage } = useExtensionVersion();

  const [settings, setSettings] = useState({
    offerToLikers: {
      enabled: false,
      discountPercentage: '0.1',
      shippingDiscount: '50'
    },
    shippingDiscountOptions: [
      {
        label: 'Free shipping on orders over $50',
        value: '50'
      },
      {
        label: 'Free shipping on orders over $100',
        value: '100'
      },
    ],
    shareCloset: {
      enabled: true,
      alarms: [
        {
          name: "Morning",
          enabled: true,
          startAt: new Date()
        },
        {
          name: "Afternoon",
          enabled: true,
          startAt: new Date()
        },
        {
          name: "Evening",
          enabled: true,
          startAt: new Date()
        },
        {
          name: "Night",
          enabled: true,
          startAt: new Date()
        }
      ]
    },
  })

  return (
    <div>
      <ResponsiveAppBar />
      <Helmet
        bodyAttributes={{ style: `background-color : ${colors.alabaster}` }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content="Poshmark Assistant" />
        <meta property="og:description" content="Automatically share your closet and make more sales." />
        <meta property="og:url" content="https://poshwatch.io/poshmark-assistant" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={href} />
      </Helmet>
      <Panel theme={lightTheme}>
        <Container maxWidth='md' style={{ paddingBottom: '40px' }}>
          <Typography variant='h4' align='center' paddingBottom={1} fontFamily={"Libre Baskerville"} style={{ paddingBottom: '20px' }}>{title}
          </Typography>
          <Typography align='center' color="textSecondary">{subtitle}
          </Typography>
        </Container>
        {showExtensionInstallWarning &&
          <Container maxWidth="lg">
            <a
              href="https://chromewebstore.google.com/detail/poshwatch/mkcklccefjfbljhgabgdnapimbmejlpo"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Alert
                severity={'error'}
                style={{
                  marginBottom: '12px',
                }}
              >
                {installExtensionMessage}
              </Alert>
            </a>
            <PlaceholderCard />
          </Container>
        }
        {showExtensionInstallWarning === false && <div id={"extension-container"} />}
      </Panel>
      <Footer disableBgColor />
    </div>
  );
};

export default Assistant;
