import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList, Tooltip, PieChart, Pie, Cell } from 'recharts';
import { Typography, Box } from '@mui/material';
import { formatNumberCompact } from '../../utils/helpers';
import { ChartData } from './data';
import { useData } from './DataProvider.tsx';
import PostTemplate from './PostTemplate.tsx';
import { getColorPallete } from './colors.tsx';

const formatItems = (value: any): string => {
  if (typeof value === 'number') {
    return formatNumberCompact(value);
  }
  if (Array.isArray(value)) {
    return value.map(v => formatItems(v)).join(', ');
  }
  return `${value}`;
};

interface ChartPostProps {
  chartType?: 'bar' | 'pie';
}

const ChartPost: React.FC<ChartPostProps> = ({ chartType = 'bar' }) => {

  const { currentData, data } = useData<ChartData>();

  if (!currentData) return null;

  const colorPallete = getColorPallete();

  const renderChart = () => {
    if (chartType === 'pie') {
      return (

        <ResponsiveContainer width="100%" height={500}>
          <PieChart>
            <Pie
              data={currentData.data}
              cx="50%"
              cy="50%"
              labelLine={true}
              outerRadius={220} // 200 to 240 works
              fill="#8884d8"
              dataKey="count"
              label={({ label, count }) => `${label}: ${formatItems(count)} Sales`}
              nameKey="label"
            >
              {currentData.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colorPallete[index % colorPallete.length]} />
              ))}
            </Pie>
            <Tooltip formatter={formatItems} />
          </PieChart>
        </ResponsiveContainer>
      );
    }

    return (
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          layout="vertical"
          data={currentData.data}
          margin={{ top: 20, right: 30, left: 140, bottom: 20 }}
        >
          <XAxis type="number" hide />
          <YAxis
            dataKey="category"
            type="category"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#000000', fontSize: 16 }}
          />
          <Tooltip formatter={formatItems} />
          <Bar dataKey="items" fill="#2D3748">
            <LabelList
              dataKey="items"
              position="right"
              formatter={formatItems}
              fill="#000000"
              fontSize={16}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <PostTemplate theme="light"
      numScreenshots={data.length}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#FFFFFF' }}>
        <Box sx={{ width: '100%', maxWidth: 900 }}>
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              fontFamily: "Libre Baskerville",
              color: '#1A202C'
            }}>
            {currentData.title}
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ fontSize: "1.5em", color: '#2D3748' }}>
            {currentData.subtitle}
          </Typography>
          {renderChart()}
        </Box>
      </Box>
    </PostTemplate>
  );
};

export default ChartPost;