import React, { useState } from 'react';
import { Card, CardContent, Container, Typography, TextField, Box, useMediaQuery, Button, CircularProgress } from '@mui/material';
import Panel from './Panel';
import { colors, lightTheme } from '../customTheme';
import { subscribeWithEmail } from "../api/client";

const NewsletterPanel = ({ compact = true }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setError(null);
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setError(null);
      setLoading(true);

      const success = await subscribeWithEmail(email);

      if (success) {
        setSuccess(true);
      }
      else {
        setError("An error occurred, please try again later");
      }
      setLoading(false);
    } else {
      setError("Invalid email address");
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubscribe();
    }
  };

  return (
    <Panel theme={lightTheme}>
      <Container maxWidth='md'>
        <Card variant="outlined" style={{
          padding: isMobile ? '0px' : compact ? '5px' : '10px', backgroundColor: '#f9fafb',
          borderRadius: 10,
        }} >
          <CardContent>
            {!compact &&
              <Typography variant='h3' style={{
                fontSize: '2.2rem',
                fontFamily: "Libre Baskerville"
              }} align='center' paddingBottom={2}>Stay informed to crush your sales goals
              </Typography>
            }
            <Typography variant='body1' color="textSecondary" pb={0}>Uncover Poshmark's best-selling brands, categories and more with our weekly rankings.
            </Typography>
            {success ? (
              <Card variant="outlined" style={{ backgroundColor: '#d3fbeb', paddingTop: '8px', borderColor: colors.green }} sx={{ mt: 2 }}>
                <CardContent>
                  <Typography variant="body" align="center" color={colors.elfGreen}>Success! Now check your email to confirm your subscription.
                  </Typography>
                </CardContent>
              </Card>

            ) : loading ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'stretch' : 'center'}>
                <TextField
                  label="Email"
                  size="small"
                  variant="outlined"
                  value={email}
                  onChange={handleEmailChange}
                  onKeyDown={handleKeyDown}
                  fullWidth={!isMobile}
                  margin="normal"
                  error={error}
                  helperText={error}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubscribe}
                  style={{
                    fontSize: "0.9rem",
                    borderRadius: 10,
                    height: 44,
                    width: isMobile ? '100%' : 220,
                    marginTop: !isMobile ? error ? -14 : 8 : 0,
                    marginLeft: isMobile ? 0 : 10,
                    boxShadow: 'none',
                    backgroundColor: colors.mineShaft,
                  }}
                >
                  Subscribe
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Container>
    </Panel>
  );
}

export default NewsletterPanel;
